import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import TheLoader from "../../components/TheLoader";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import InputMask from "react-input-mask";
import GetSession from "../../composable/GetSession";
import SetSession from "../../composable/SetSession";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompressImage from "../../composable/CompressImage";
import CustomSelect from "../../components/CustomSelect";

const WarrentyRegistration = () => {
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setError] = useState({ status: null });
  const [dealers, setDealers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [products, setProducts] = useState([]);
  const [serieNummer, setSerieNummer] = useState([]);
  const [factuur, setFactuur] = useState([]);
  const { t } = useTranslation();

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    adapter: "jsonAdapter",
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  const updateSucces = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let formDataArray = new FormData();
    formDataArray.append("article_id", formData.article_id);
    formDataArray.append("product_amount", 1);
    formDataArray.append("serialnumber", formData.serialnumbers);
    formDataArray.append("dealer_id", formData.dealer_id);
    formDataArray.append("purchase_date", formData.purchase_date);
    formDataArray.append("serialnumber_image", serieNummer.url);
    formDataArray.append("purchase_image", factuur.url);
    formDataArray.append("terms_and_conditions", formData.terms_and_conditions);

    if (formData.terms_and_conditions === 1) {
      axios
        .post(`${process.env.REACT_APP_API_URL}warranty-registration`, formDataArray, axiosConfig)
        .then(function (response) {
          setLoading(false);
          updateSucces(response.data.message);
          setFormData([]);
          setSerieNummer([]);
          setFactuur([]);
          setError({ status: null });
        })
        .catch(function (error) {
          setLoading(false);
          setError(error.response.data.errors);
          toast(t("error.invalid-data"), {
            position: "top-center",
            type: "error",
          });
        });
      setLoading(false);
    } else {
      setError({ status: t("error.agree-terms") });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      if (sessionStorage.getItem("warrantyProducts")) {
        setProducts(JSON.parse(sessionStorage.getItem("warrantyProducts")));
        setLoading(false);
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}warranty-registration/articles`)
          .then((response) => {
            setProducts(response.data.data);
            sessionStorage.setItem("warrantyProducts", JSON.stringify(response.data.data));
            setLoading(false);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      if (GetSession("dealers") !== null) {
        setDealers(GetSession("dealers"));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}dealer`)
          .then((response) => {
            setDealers(response.data.data);
            SetSession("dealers", response.data.data);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
      setTimeout(() => {
        document.getElementById("purchase_date").max = new Date().toISOString().split("T")[0];
      }, 500);

      setFirstLoad(true);
    }
  }, []);

  const handleTerms = (e) => {
    e.target.checked
      ? setFormData({ ...formData, terms_and_conditions: 1 })
      : setFormData({ ...formData, terms_and_conditions: 0 });
  };

  const handleDealer = ({ value }) => {
    setFormData({ ...formData, dealer_id: value });
  };

  const handleProduct = ({ value }) => {
    setFormData({ ...formData, article_id: JSON.parse(value).article_id });
  };

  const uploadImage = async (file, stateName) => {
    if (file.size <= 5000000) {
      const fileType = file.type.split("/")[0];

      if (fileType === "image") {
        file = await CompressImage(file);
      }

      const image = new FormData();
      image.append("file", file);

      axios
        .post(`${process.env.REACT_APP_API_URL}file/upload`, image, {
          headers: {
            ...axiosConfig.headers,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (stateName === "serieNummer") {
            setSerieNummer(response.data);
          } else if (stateName === "factuur") {
            setFactuur(response.data);
          }
        })
        .catch(function (error) {
          setError(error.response.data.errors);
        });
    } else {
      if (stateName === "serieNummer") {
        setError({
          serialnumber_image: t("error.file-too-large"),
        });
      } else if (stateName === "factuur") {
        setError({
          purchase_image: t("erorr.file-too-large"),
        });
      }
    }
  };

  return (
    <>
      {/* TheLoader */}
      <TheLoader isLoading={loading} />

      <Layout Title={t("warranty-registration.title")}>
        <div className="flex w-full items-center justify-between">
          <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
            {t("warranty-registration.introduction")}
          </span>
          <Link to="./registraties">
            <button className="btn btn--primary">
              {t("warranty-registration.registered-warranties")}
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
            </button>
          </Link>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-2 gap-x-4 gap-y-6">
          <div className="col-span-full">
            <p className="pb-3 text-sm">{t("general.product")}</p>
            <CustomSelect
              id="article_id"
              placeholder={t("platform-help.select-product")}
              options={products.map((product) => ({
                value: JSON.stringify(product),
                label: `${product.article_number} ${product.name !== null ? " - " + product.name : ""}`,
              }))}
              onChange={handleProduct}
            />
            {formError.article_id && <span className="mt-2 text-red-500">{formError.article_id}</span>}
          </div>
          <div className="col-span-full md:col-span-1">
            <div>
              <p className="pb-3 text-sm">{t("general.date-of-purchase")}</p>
              <input
                id="purchase_date"
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    purchase_date: e.target.value,
                  })
                }
                className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
              />
            </div>
            {formError.purchase_date && <span className="mt-2 text-red-500">{formError.purchase_date}</span>}
          </div>

          <div className="col-span-full md:col-span-1">
            <div>
              <p className="pb-3 text-sm">{t("general.dealer")}</p>
              <CustomSelect
                id="dealer_id"
                placeholder={t("general.select-dealer")}
                options={dealers.map((dealer) => ({
                  value: dealer.id,
                  label: dealer.name,
                }))}
                onChange={handleDealer}
              />
            </div>
            {formError.dealer && <span className="mt-2 text-red-500">{formError.dealer}</span>}
          </div>

          {formData.article_id && (
            <div className="col-span-full md:col-span-1">
              <p className="pb-3 text-sm">{t("general.serialnumber")}</p>
              <InputMask
                mask="999999999"
                id="serialnumbers"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    serialnumbers: e.target.value,
                  })
                }
                placeholder={t("general.serialnumber")}
                className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                required
              />
              {formError.serialnumbers && <span className="mt-2 text-red-500">{formError.serialnumbers}</span>}
            </div>
          )}

          {formData.article_id && formData.article_id.serialnumber_photo !== null && (
            <div className="col-span-full md:col-span-1">
              <p className="pb-3 text-sm">{t("general.serialnumber-example")}</p>

              <div className="dark:glass relative mx-auto rounded-xl bg-white p-3 shadow-md">
                <p className="pb-3 text-sm text-black/50 dark:text-white/50">{t("general.serialnumber-placeholder")}</p>

                <img
                  src={formData.article_id.serialnumber_photo}
                  alt={formData.article_id.serialnumber_comment}
                  className="rounded-xl"
                />
              </div>
            </div>
          )}

          <div className="col-span-full md:col-span-1">
            <div>
              <label>
                <input
                  type="file"
                  hidden
                  id="serialnumber_photo"
                  onChange={(e) => uploadImage(e.target.files[0], "serieNummer")}
                />
                <p className="pb-3 text-sm">{t("general.serialnumber-photo")}</p>
                <div className="btn btn--secondary justify-start">
                  <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.serialnumber-photo-upload")}
                </div>
              </label>
            </div>

            {formError.serialnumber_image && <span className="mt-2 text-red-500">{formError.serialnumber_image}</span>}

            {serieNummer.url && (
              <img
                src={serieNummer.url + "?token=" + localStorage.getItem("userAuth")}
                alt={serieNummer.url}
                className="mt-3 w-1/2 object-cover"
              />
            )}
          </div>

          <div className="col-span-full md:col-span-1">
            <div>
              <label>
                <input
                  type="file"
                  hidden
                  id="purchase_image"
                  onChange={(e) => uploadImage(e.target.files[0], "factuur")}
                />

                <p className="pb-3 text-sm">{t("general.invoice-photo")}</p>
                <div className="btn btn--secondary justify-start">
                  <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.invoice-upload")}
                </div>
              </label>
            </div>
            {formError.purchase_image && <span className="mt-2 text-red-500">{formError.purchase_image}</span>}

            {factuur.url && (
              <img
                src={factuur.url + "?token=" + localStorage.getItem("userAuth")}
                alt={factuur.url}
                className="mt-3 w-1/2 object-cover"
              />
            )}
          </div>

          <div className="col-span-full">
            <input type="checkbox" id="privacy_statement" required onChange={(e) => handleTerms(e)} />
            <span className="ml-2 text-sm">
              <Trans
                i18nKey="general.privacy-policy-consent"
                components={{
                  link1: (
                    <a
                      href={process.env.REACT_APP_PRIVACY_POLICY}
                      download
                      target="_blank"
                      className="link text-primary dark:text-primary-light"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          </div>

          <div className="col-span-full">
            <button type="submit" className="btn btn--primary w-full" value="registreren">
              {t("warranty-registration.request-warranty")}
              <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default WarrentyRegistration;
