import React from "react";
import { useTranslation } from "react-i18next";
import LandingspageNavbar from "../components/navbar/LandingpageNavbar";
import Button from "../components/Button";
import GuestNav from "../components/navbar/GuestNav";
import Nav from "../components/navbar/Nav";

const ErrorPage = () => {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      <div>
        <LandingspageNavbar />

        {!userData ? <GuestNav page={"404"} /> : <Nav page={"404"} />}
      </div>

      <div className={"grid grid-cols-12"}>
        <div className="content default-cols default-cols container px-4 pb-28 pt-60">
          <div className="relative grid grid-cols-1 gap-y-10">
            <div className="dark:glass col-span-1 flex flex-col rounded-xl bg-white px-4 py-3 shadow-md">
              <span className="font-robotoslab text-3xl font-bold">{t("error-page.title")}</span>
            </div>
            <div className="col-span-1 flex flex-col justify-between gap-y-4 md:flex-row md:gap-x-4">
              {userData ? (
                <Button to="/home" icon="arrow-left" text={t("error-page.back-to-home")} primary wfull />
              ) : (
                <>
                  <Button to="/login" icon="key" text={t("general.login")} primary arrow wfull />
                  <Button to="/" icon="arrow-left" text={t("error-page.back-to-start")} primary wfull />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
