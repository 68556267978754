function sessionGet(key) {
  let stringValue = window.sessionStorage.getItem(key);
  if (stringValue !== null) {
    let value = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.value;
    } else {
      window.sessionStorage.removeItem(key);
    }
  }
  return null;
}
export default sessionGet;
