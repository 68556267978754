import React from "react";

const ImageCard = ({ IMG, children, mdSmall }) => {
  return (
    <div className="dark:glass flex flex-col justify-between rounded-xl bg-white shadow-md">
      <img
        src={IMG}
        className={`aspect-[16/9] ${mdSmall ? "md:aspect-[16/5]" : "h-full lg:aspect-auto"} w-full rounded-t-xl object-cover`}
        alt="Visual for the card"
      />
      <main className="p-6">{children}</main>
    </div>
  );
};

export default ImageCard;
