import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({ icon, text, to, arrow, primary, wfull }) => {
  return (
    <Link to={to} className={`btn group ${primary ? "btn--primary" : "btn--secondary"} ${wfull && "w-full"}`}>
      <div className="flex items-center justify-start gap-2">
        {icon && (
          <div className="w-5">
            <FontAwesomeIcon icon={`fa-regular fa-${icon}`} />
          </div>
        )}
        {text}
      </div>
      {arrow && (
        <FontAwesomeIcon
          icon="fa-regular fa-arrow-right-long "
          className={`${primary ? "" : "text-primary dark:text-primary-light"} animate group-hover:translate-x-0.5`}
        />
      )}
    </Link>
  );
};
export default Button;
