import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iOS from "../../composable/ios";
import CustomSelect from "../CustomSelect";
import { useDebouncedCallback } from "use-debounce";
import TheLoader from "../TheLoader";

const Products = ({ guestAccount }) => {
  let navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [cart, setCart] = useState({ articles: [], totalItems: 0, totalPrice: 0 });
  const [toggleShoppingCart, setToggleShoppingCart] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [terms, setTerms] = useState(false);
  const [conditions, setConditions] = useState(false);
  const [points, setPoints] = useState("0");
  const debouncedSearch = useDebouncedCallback((value) => setSearch(value), 700);

  const { t } = useTranslation();

  const amounts = Array.from({ length: 25 }, (_, i) => ({ value: i + 1, label: i + 1 }));
  const only_one = [{ value: 1, label: 1 }];
  const notifications = JSON.parse(sessionStorage.getItem("Notifications"));

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      if (!userData) {
        setFirstLoad(true);
        return;
      }

      axios.get(`${process.env.REACT_APP_API_URL}shop/cart`).then((res) => {
        handleUpdateCart(res.data.items);
        setLoading(false);
        setFirstLoad(true);
      });
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      adapter: "jsonAdapter",
    };
    axios.get(`${process.env.REACT_APP_API_URL}user/@me`).then((res) => {
      if (localStorage.getItem("userAuth") !== undefined) {
        setPoints(res.data.data.points);
      } else {
        setTimeout(() => {
          setPoints("0");
        }, 2000);
      }
    });
  }, []);

  // Handle Update Card
  async function handleUpdateCart(items) {
    let cartData = {
      articles: [],
      totalItems: 0,
      totalPrice: 0,
    };

    // add items to cart
    // forEach object in object items
    Object.keys(items).forEach((key) => {
      cartData.articles.push(items[key]);
      cartData.totalItems += 1;
      cartData.totalPrice += parseInt(items[key].quantity) * items[key].price;
    });

    // set cart
    setCart(cartData);
    localStorage.setItem("shopping-cart", JSON.stringify(cartData));
  }

  // Notify
  const notifyNoPoints = () =>
    toast.error(t("gift-shop.not-enough-points"), {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const toastError = (text) =>
    toast.error(text, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySelectAmount = () =>
    toast.error(t("gift-shop.select-quantity"), {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    accept: "application/json",
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}shop/article?search=` + search)
      .then((response) => {
        setArticles(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(t("error.something-went-wrong"), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
        });
        return error;
      });
  }, [search]);

  // Show error message if only one per account is active
  const handleQuantityClick = (isOnePerAccount) => {
    if (isOnePerAccount) {
      toastError(t("gift-shop.only-one-per-account"));
    }
  };

  // Handle Add to cart
  const handleUpdateCartItem = async (e, valueData, amountData) => {
    e.preventDefault();

    let value = valueData;
    let amount = amountData;

    if (value === undefined || amount === undefined) {
      value = e.target.elements.option.value;
      const element = document.getElementById(`amount-${value}`);
      amount = parseInt(element.innerText);
    }
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}shop/cart/` + value, {
        option: value,
        amount: amount,
        row: value,
      })
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        handleUpdateCart(response.data.items);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.request.status === 403 || error.request.status === 406) {
          toastError(error.response.data.message);
        }
        if (error.request.status === 500) {
          notifySelectAmount();
        }
        setLoading(false);
      });
  };

  const handleRemoveCartItem = async (item) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}shop/cart/` + item.id)
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleUpdateCart(response.data.items);
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
        toast.error(t("error.something-went-wrong"), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
        });
      });
  };

  const handleUpdateCartItemAmount = async (item, adjustment) => {
    setLoading(true);
    let amount = item.quantity;

    if (adjustment === "plus") {
      amount = parseInt(item.quantity) + 1;
    } else {
      if (amount === 1 || adjustment === "delete") {
        handleRemoveCartItem(item);
        return;
      }
      amount = parseInt(item.quantity) - 1;
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}shop/cart/` + item.id, {
        amount: amount,
      })
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleUpdateCart(response.data.items);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.request.status === 403 || error.request.status === 406) {
          toastError(error.response.data.message);
        }
        if (error.request.status === 500) {
          notifySelectAmount();
        }
        setLoading(false);
      });
  };

  const updateTerms = (e) => {
    setTerms(e);
  };

  const updateConditions = (e) => {
    setConditions(e);
  };

  const handleOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}shop/order`, {
        shop_terms: terms,
        terms_and_conditions: conditions,
      })
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        // TODO: test redirect
        navigate("/bestellingen/" + response.data.shopOrder.id);

        setLoading(false);
      })
      .catch(function (error) {
        if (error.request.status === 403) {
          notifyNoPoints();
        }
        if (error.request.status === 500) {
          toast.error(t("error.something-went-wrong"), {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
          });
        }
        if (error.request.status === 422) {
          //foreach error
          Object.entries(error.response.data.errors).forEach(([value]) => {
            toast.error(value[0], {
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: false,
            });
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className={`bg-nav dark:bg-nav-dark fixed left-0 top-0 z-50 grid w-full grid-cols-12 ${iOS() ? "pt-12" : "pt-4"}`}
      >
        <div className="default-cols flex w-full items-center justify-between px-4 py-2">
          <div className="container flex lg:px-0">
            {!toggleShoppingCart ? (
              <button onClick={handleGoBack} className="flex w-20 items-center justify-start py-1">
                <FontAwesomeIcon icon="fa-regular fa-chevron-left" />
              </button>
            ) : (
              <button
                onClick={() => setToggleShoppingCart(!toggleShoppingCart)}
                className="flex w-20 items-center justify-start py-1"
              >
                <FontAwesomeIcon icon="fa-regular fa-xmark" />
              </button>
            )}
            <div className="flex w-full items-center justify-center">
              <span className="w-full text-center font-robotoslab">{t("gift-shop.title")}</span>
            </div>
            <div className="flex w-20 items-center justify-end gap-x-4">
              <Link to="../notifications" className={`w-6 py-1 text-center ${notifications && 'has-notifications'}`} >
                <FontAwesomeIcon icon="fa-regular fa-bell" />
              </Link>
              <Link to="../profiel" className="w-6 py-1 text-center">
                <FontAwesomeIcon icon="fa-regular fa-user" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Top */}
      {!guestAccount && !toggleShoppingCart && (
        <>
          <div className="mb-6 flex items-center justify-between">
            <span className="text-sm">
              <span className="font-bold">{points}</span> {t("general.gift-points")}
            </span>

            <Link to="bestellingen" className="btn btn--primary">
              {t("general.previous-orders")}
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
            </Link>
          </div>
        </>
      )}

      <div>
        {userData && (
          <>
            <div className={`fixed left-0 right-0 z-50 grid  w-full grid-cols-12 ${iOS() ? "bottom-24" : "bottom-20"}`}>
              <div className="default-cols container flex justify-between px-4 lg:px-0">
                <div>
                  <div className="dark:glass flex h-[46px] w-max items-center justify-between rounded-xl bg-white px-3  shadow-md">
                    <span className="font-bold">{points}</span>
                    &nbsp;
                    {t("general.gift-points")}
                  </div>
                </div>
                <div
                  className="animate relative flex size-11 cursor-pointer items-center justify-center rounded-xl bg-primary text-white shadow-md hover:bg-primary-light dark:bg-primary-light dark:hover:bg-primary"
                  onClick={() => setToggleShoppingCart(!toggleShoppingCart)}
                >
                  {toggleShoppingCart ? (
                    <FontAwesomeIcon icon="fa-regular fa-times" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon="fa-regular fa-shopping-cart" />
                      {cart.totalItems > 0 && (
                        <div className="absolute -right-2 -top-2 flex size-[18px] items-center justify-center rounded-full bg-makita-red text-sm">
                          {cart.totalItems}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {!toggleShoppingCart && <TheLoader isLoading={loading} />}

        {!toggleShoppingCart && (
          <div className="mb-6 grid grid-cols-4">
            <div className="relative col-span-full flex justify-between gap-4">
              <div className="relative w-full">
                <div className="absolute left-4 top-2">
                  <FontAwesomeIcon icon="fa-regular fa-search" />
                </div>
                <input
                  className="h-10 w-full rounded-full py-2 pl-11 pr-4 text-sm shadow-md dark:bg-secondary-dark"
                  placeholder={t("general.search")}
                  type="text"
                  id="search"
                  onChange={(e) => debouncedSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="mb-6 grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-2">
          {!toggleShoppingCart && (
            <>
              {articles.map((article) => {
                return (
                  <div
                    className="dark:glass grid grid-cols-3 rounded-xl bg-white pr-6 shadow-md dark:!backdrop-blur-none"
                    key={article.id}
                  >
                    <div className="col-span-1">
                      <div className="flex h-full items-center justify-center rounded-l-xl bg-white p-4">
                        <img src={article.images[0]} className="object-contain" alt={article.name} loading="lazy" />
                      </div>
                    </div>
                    <div className="col-span-2 flex flex-col gap-1.5 py-6 pl-4">
                      <span className="font-bold text-primary dark:text-primary-light">{article.name}</span>

                      {article.description !== "" && (
                        <div>
                          <span className="font-robotoslab text-sm">
                            <span dangerouslySetInnerHTML={{ __html: article.description }}></span>
                          </span>
                        </div>
                      )}
                      <div className="mb-1">
                        <span className="text-sm">
                          <strong className="text-xl text-primary dark:text-primary-light">{article.points}</strong>{" "}
                          {t("gift-shop.gift-points")}
                        </span>
                      </div>
                      {userData && (
                        <form className="mt-auto" onSubmit={handleUpdateCartItem} method="post">
                          <div className="col-span-2 col-end-4 flex items-center justify-between gap-x-3 lg:justify-end">
                            <div onClick={() => handleQuantityClick(article.one_per_account)}>
                              <CustomSelect
                                id={"amount-" + article.id}
                                options={article.one_per_account ? only_one : amounts}
                                defaultValue={amounts.filter((amount) => amount.value === 1)}
                                secondary
                                isDisabled={article.one_per_account}
                              />
                            </div>
                            <input className="hidden" value={article.id} id="option" />

                            {points < article.points ? (
                              <button className="btn btn--primary" type="submit" disabled>
                                {t("gift-shop.order")}
                              </button>
                            ) : (
                              <button className="btn btn--primary" type="submit">
                                {t("gift-shop.order")}
                              </button>
                            )}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>

        {/*  Shoppingcart Popout  */}
        {toggleShoppingCart && (
          <div className="left-0 top-0 z-40 h-full w-full">
            <div className="grid h-full w-full grid-cols-12 pb-8 opacity-100">
              <div className="col-span-full">
                <div className="flex flex-col gap-4">
                  {cart.articles.map((article) => {
                    return (
                      <div className="dark:glass grid grid-cols-3 rounded-xl bg-white pr-6 shadow-md" key={article.id}>
                        <div className="col-span-1">
                          <div className="flex h-full items-center justify-center rounded-l-xl bg-white p-4">
                            {article.associatedModel.media && (
                              <img
                                src={article.associatedModel.media[0].original_url}
                                className="object-contain"
                                alt={article.name}
                                loading="lazy"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-span-2 flex flex-col gap-1.5 py-6 pl-4">
                          <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
                            {article.name}
                          </span>

                          {article.description !== "" && (
                            <div>
                              <span className="font-robotoslab text-sm">
                                <span dangerouslySetInnerHTML={{ __html: article.description }}></span>
                              </span>
                            </div>
                          )}
                          <div className="mb-3">
                            <span className="text-sm">
                              <strong className="text-xl text-primary dark:text-primary-light">{article.price}</strong>{" "}
                              {t("gift-shop.gift-points")}
                            </span>
                          </div>

                          <div className="mt-auto">
                            <div className="inline-flex w-full items-center justify-between">
                              <button
                                className="animate flex size-9 items-center justify-center rounded-xl bg-bg hover:bg-button-primary dark:bg-secondary-dark dark:text-white dark:hover:bg-secondary"
                                onClick={() => handleUpdateCartItemAmount(article, "delete")}
                              >
                                <FontAwesomeIcon icon="fa-regular fa-trash " />
                              </button>
                              <button
                                className="animate flex size-9 items-center justify-center rounded-xl bg-button-primary shadow-md hover:bg-primary-light dark:bg-primary dark:hover:bg-primary-light"
                                onClick={() => handleUpdateCartItemAmount(article, "minus")}
                              >
                                <FontAwesomeIcon icon="fa-regular fa-minus" />
                              </button>
                              <span className="font-robotoslab">{article.quantity}</span>
                              <button
                                className="animate flex size-9 items-center justify-center rounded-xl bg-button-primary shadow-md hover:bg-primary-light disabled:pointer-events-none disabled:opacity-30 dark:bg-primary dark:hover:bg-primary-light"
                                onClick={() => handleUpdateCartItemAmount(article, "plus")}
                                disabled={article.associatedModel.one_per_account}
                              >
                                <FontAwesomeIcon icon="fa-regular fa-plus" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <hr className="my-6 border-line" />

                <div className="flex justify-between">
                  <div className="flex flex-[1] flex-col">
                    <p>{t("general.post-address")}</p>
                    <Link
                      to="/profiel/adress-aanpassen"
                      className="animate text-primary hover:opacity-50 dark:text-primary-light"
                    >
                      {t("profile.edit-address")}
                    </Link>
                  </div>
                  <p className="flex-[1] text-right">
                    {userData.address.street} {userData.address.housenumber}
                    <br /> {userData.address.postal} {""}
                    {userData.address.city}
                  </p>
                </div>

                <hr className="my-6 border-line" />

                <div className="flex justify-between">
                  <p className="text-sm font-bold">
                    {t("gift-shop.gift-points")} {t("gift-shop.available")}:
                  </p>
                  <p className="text-right text-sm">{points}</p>
                </div>
                <div className="my-3 flex justify-between">
                  <p className="text-sm font-bold">{t("gift-shop.total-points")}:</p>
                  <p className="text-right text-sm font-bold">{cart.totalPrice}</p>
                </div>

                <div className="w-full">
                  <form onSubmit={handleOrder} method="post">
                    <div className="flex flex-col gap-y-3">
                      <label className="flex gap-3 text-sm">
                        <input
                          type="checkbox"
                          onChange={(e) => updateConditions(e.target.checked)}
                          value={conditions}
                        />
                        <div>
                          {t("gift-shop.i-accept")}{" "}
                          <a
                            href={`${process.env.REACT_APP_GENERAL_TERMS}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="animate text-primary hover:opacity-50 dark:text-primary-light"
                          >
                            {t("gift-shop.terms-and-conditions")}
                          </a>
                        </div>
                      </label>
                      <label className="mb-3 flex gap-3 text-sm">
                        <input type="checkbox" onChange={(e) => updateTerms(e.target.checked)} value={terms} />
                        <div>
                          {t("gift-shop.i-accept")}{" "}
                          <a
                            href={`${process.env.REACT_APP_SHOP_TERMS}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="animate text-primary hover:opacity-50 dark:text-primary-light"
                          >
                            {t("gift-shop.shop-terms")}
                          </a>
                        </div>
                      </label>
                      <button type="submit" className="btn btn--primary w-full">
                        {t("gift-shop.order")} <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Products;
