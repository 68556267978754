import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import iOS from "../../composable/ios";

const Nav = () => {
  const page = window.location.pathname.split("/")[1];

  return (
    <nav className="sticky-nav dark:glass dark:glass--dark z-[99] grid w-full grid-cols-12 bg-white px-4">
      <div className={`${iOS() ? "pb-[29px] pt-3" : "py-3"} default-cols`}>
        <div className="flex items-center justify-between">
          <Link to={"/home"}>
            <div
              className={`group flex size-12 items-center justify-center ${page === "home" ? "nav-item-active" : "nav-item"}`}
            >
              {page === "home" ? (
                <FontAwesomeIcon icon="fa-solid fa-home" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-home" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/cadeauwinkel"}>
            <div
              className={`group flex size-12 items-center justify-center ${page === "cadeauwinkel" ? "nav-item-active" : "nav-item"}`}
            >
              {page === "cadeauwinkel" ? (
                <FontAwesomeIcon icon="fa-solid fa-shopping-bag" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-shopping-bag" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/actieregistratie"}>
            <div
              className={`group flex size-12 items-center justify-center ${page === "actieregistratie" ? "nav-item-active" : "nav-item"}`}
            >
              {page === "actieregistratie" ? (
                <FontAwesomeIcon icon="fa-solid fa-tags" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-tags" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/garantieregistratie"}>
            <div
              className={`group flex size-12 items-center justify-center ${page === "garantieregistratie" ? "nav-item-active" : "nav-item"}`}
            >
              {page === "garantieregistratie" ? (
                <FontAwesomeIcon icon="fa-solid fa-briefcase" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-briefcase" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/more"}>
            <div
              className={`group flex size-12 items-center justify-center ${page === "more" ? "nav-item-active" : "nav-item"}`}
            >
              {page === "more" ? (
                <FontAwesomeIcon icon="fa-solid fa-ellipsis" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-ellipsis" className="nav-icon" />
              )}
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
