import React from "react";
import { useTranslation } from "react-i18next";
import LandingspageNavbar from "../../components/navbar/LandingpageNavbar";
import CapacitorController from "../../components/CapacitorController";
import iOS from "../../composable/ios";

const ForgotPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="lg:px-40">
        <LandingspageNavbar page="/" button={t("general.login")} icon={false} />
      </div>

      <div className={`grid grid-cols-12 ${iOS() ? "mt-6" : "mt-12"}`}>
        <div className="content default-cols container px-4">
          <div className="content grid grid-cols-1 gap-5 pb-28 pt-60 md:grid-cols-2 md:pt-72 lg:grid-cols-3">
            <div className="col-span-full rounded-md bg-primary p-6 text-white">
              <h3 className="col-span-full text-xl font-bold">{t("general.forgot-password-success")}</h3>
            </div>
          </div>
        </div>
        <CapacitorController />
      </div>
    </>
  );
};

export default ForgotPasswordSuccess;
