import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iOS from "../../composable/ios";
import { useNavigate } from "react-router-dom";

const TopNavGuest = ({ Title }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`bg-nav dark:bg-nav-dark fixed left-0 top-0 z-50 grid w-full grid-cols-12 ${iOS() ? "pt-12" : "pt-4"}`}
    >
      <div className="default-cols flex w-full items-center justify-between px-4 py-3">
        <div className="container flex">
          <button onClick={handleGoBack} className="flex w-8 items-center justify-start">
            <FontAwesomeIcon icon="fa-regular fa-chevron-left" />
          </button>
          <div className="flex w-full justify-center">
            <span className="w-full text-center font-robotoslab">{Title}</span>
          </div>
          <button onClick={handleGoBack} className="flex w-8 items-center justify-end">
            <FontAwesomeIcon icon="fa-regular fa-xmark" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNavGuest;
