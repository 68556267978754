import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TheLoader from "../../components/TheLoader";
import CustomSelect from "../../components/CustomSelect";
import CompressImage from "../../composable/CompressImage";

const ProfileEdit = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const [formError, setError] = useState("");
  const [branches, setBranches] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [avatar, setAvatar] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const { t } = useTranslation();
  const accountTypes = [
    { value: "professional", label: t("general.professional") },
    { value: "private", label: t("general.private") },
  ];
  const companyTypes = [
    { value: "bv", label: t("general.bv") },
    { value: "vof", label: t("general.vof") },
    { value: "zzp", label: t("general.zzp") },
    { value: "club", label: t("general.club") },
  ];
  const genders = [
    { value: "M", label: t("general.male") },
    { value: "F", label: t("general.female") },
    { value: "X", label: t("general.other") },
  ];

  let navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("userData"));

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "Content-Type": "application/json",
    },
  };

  const handleAvatar = async (file) => {
    if (file.size >= 5000000) {
      setError({
        avatar: t("error.file-too-large"),
      });
      return;
    }

    const fileType = file.type.split("/")[0];
    if (fileType !== "image") {
      setError({
        avatar: t("error.not-an-image"),
      });
      return;
    }

    file = await CompressImage(file);

    const image = new FormData();
    image.append("file", file);

    axios
      .post(`${process.env.REACT_APP_API_URL}file/upload`, image, {
        headers: {
          ...axiosConfig.headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function ({ data }) {
        setAvatar(data.url);
      })
      .catch(function () {
        setError({
          avatar: t("error.uploading-image"),
        });
      });
  };

  // Handle Sumbit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataObject = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phonenumber: formData.phonenumber,
      gender: formData.gender,
      type: formData.type,
      company_name: formData.company_name,
      company_type: formData.company_type,
      btw: formData.btw,
      kvk: formData.kvk,
      branche_id: formData.branche_id,
    };

    if (avatar) {
      formDataObject.avatar = avatar;
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}user/profile`, formDataObject, axiosConfig)
      .then(function (response) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        userData = response.data.data;
        setLoading(false);
        updateSuccess(response.data.message);
        navigate("/profiel");
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setError(error.response.data.errors);
        } else {
          toast(t("error.something-went-wrong"), {
            position: "top-center",

            type: "error",
          });
        }
        setLoading(false);
      });
  };

  // OnFirstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}branch`)
        .then((res) => {
          setBranches(res.data.data);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.something-went-wrong"), {
            position: "top-center",

            type: "error",
          });
          setLoading(false);
        });

      setFormData({
        ...formData,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phonenumber: userData.phonenumber,
        gender: userData.gender,
        type: userData.is_company ? "professional" : "private",
        company_name: userData.company.company_name,
        company_type: userData.company.company_type,
        btw: userData.company.btw,
        kvk: userData.company.kvk,
        branche_id: userData.company.branche_id,
      });

      setFirstLoad(true);
    }
  }, [
    firstLoad,
    formData,
    userData.avatar,
    userData.company.branche_id,
    userData.company.btw,
    userData.company.company_name,
    userData.company.company_type,
    userData.company.kvk,
    userData.first_name,
    userData.gender,
    userData.is_company,
    userData.last_name,
    userData.phonenumber,
    avatar,
  ]);

  // toastify
  const updateSuccess = (message) =>
    toast.success(message ? message : t("profile.profile-updated"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("profile.profile-data")}>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-5 gap-4 md:grid-cols-3">
            <div className="col-span-full mb-3">
              <div className="relative w-max">
                {!userData.avatar && !avatar && (
                  <img
                    src="https://media.istockphoto.com/vectors/default-avatar-photo-placeholder-icon-grey-profile-picture-business-vector-id1327592449?k=20&m=1327592449&s=612x612&w=0&h=6yFQPGaxmMLgoEKibnVSRIEnnBgelAeIAf8FqpLBNww="
                    alt="profile-pic"
                    className="aspect-square size-20 rounded-full bg-primary object-cover p-4"
                  />
                )}
                {userData.avatar && !avatar && (
                  <img
                    src={`${userData.avatar}?token=${localStorage.getItem("userAuth")}`}
                    alt="profile-pic"
                    className="aspect-square size-20 rounded-full bg-primary object-cover"
                  />
                )}
                {avatar && (
                  <img
                    src={`${avatar}?token=${localStorage.getItem("userAuth")}`}
                    alt="profile-pic"
                    className="aspect-square size-20 rounded-full bg-primary object-cover"
                  />
                )}
                <div className="absolute -bottom-3 -right-3 flex">
                  <label>
                    <input type="file" hidden id="avatar" onChange={(e) => handleAvatar(e.target.files[0])} />
                    <div className="flex h-10 w-10 items-center justify-center rounded-xl bg-primary p-2">
                      <FontAwesomeIcon icon="fa-solid fa-pencil" className="text-white" />
                    </div>
                  </label>
                </div>
              </div>
              {formError.avatar && <div className="mt-4 text-red-500">{formError.avatar}</div>}
            </div>
          </div>
          <div className="mt-4 grid w-full grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-4">
            <div className="col-span-full">
              <p className="pb-3 text-sm">{t("general.account-type")}</p>
              <CustomSelect
                id="type"
                placeholder={t("general.select-account-type")}
                options={accountTypes}
                defaultValue={accountTypes.filter(({ value }) => value === formData.type)}
                onChange={({ value }) =>
                  setFormData({
                    ...formData,
                    type: value,
                  })
                }
                isDisabled
              />
            </div>
            {formData.type === "professional" && (
              <>
                <div>
                  <p className="pb-3 text-sm">{t("general.company-name")}</p>
                  <input
                    id="company_name"
                    value={formData.company_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        company_name: e.target.value,
                      })
                    }
                    placeholder={t("general.company-name")}
                    className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
                  />
                  {formError.company_name && <p className="mt-1 text-makita-red">{formError.company_name}</p>}
                </div>
                <div>
                  <p className="pb-3 text-sm">{t("general.legal-form")}</p>
                  <CustomSelect
                    id="company_type"
                    placeholder={t("general.choose-legal-form")}
                    options={companyTypes}
                    defaultValue={companyTypes.filter(({ value }) => value === formData.company_type)}
                    onChange={({ value }) =>
                      setFormData({
                        ...formData,
                        company_type: value,
                      })
                    }
                  />

                  {formError.company_type && <p className="mt-1 text-makita-red">{formError.company_type}</p>}
                </div>
                <div>
                  <p className="pb-3 text-sm">{t("general.coc")}</p>
                  <input
                    id="kvk"
                    value={formData.kvk}
                    onChange={(e) => setFormData({ ...formData, kvk: e.target.value })}
                    placeholder={t("general.enter-coc-number")}
                    className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
                  />

                  {formError.kvk && <p className="mt-1 text-makita-red">{formError.kvk}</p>}
                </div>
                <div>
                  <p className="pb-3 text-sm">{t("general.vat")}</p>
                  <input
                    id="btw"
                    value={formData.btw}
                    onChange={(e) => setFormData({ ...formData, btw: e.target.value })}
                    placeholder={t("general.enter-vat-number")}
                    className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
                  />

                  {formError.btw && <p className="mt-1 text-makita-red">{formError.btw}</p>}
                </div>
                <div>
                  <p className="pb-3 text-sm">{t("general.industry")}</p>
                  {branches !== "" && (
                    <CustomSelect
                      id="branche_id"
                      placeholder={t("general.choose-industry")}
                      options={branches.map((branche) => ({
                        value: branche.id,
                        label: branche.name,
                      }))}
                      defaultValue={branches
                        .filter((branche) => branche.id === formData.branche_id)
                        .map((branche) => ({
                          value: branche.id,
                          label: branche.name,
                        }))}
                      onChange={({ value }) =>
                        setFormData({
                          ...formData,
                          branche_id: value,
                        })
                      }
                    />
                  )}

                  {formError.branche_id && <p className="mt-1 text-makita-red">{formError.branche_id}</p>}
                </div>
              </>
            )}
            <div>
              <p className="pb-3 text-sm">{t("general.gender")}</p>
              <CustomSelect
                id="gender"
                placeholder={t("general.choose-gender")}
                options={genders}
                defaultValue={genders.filter(({ value }) => value === formData.gender)}
                onChange={({ value }) =>
                  setFormData({
                    ...formData,
                    gender: value,
                  })
                }
              />

              {formError.gender && <p className="mt-1 text-makita-red">{formError.gender}</p>}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.first-name")}</p>
              <input
                id="first_name"
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    first_name: e.target.value,
                  })
                }
                placeholder={t("general.first-name")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.first_name && <p className="mt-1 text-makita-red">{formError.first_name}</p>}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.last-name")}</p>
              <input
                id="last_name"
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    last_name: e.target.value,
                  })
                }
                placeholder={t("general.last-name")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.last_name && <p className="mt-1 text-makita-red">{formError.last_name}</p>}
            </div>
            <div className="col-span-full">
              <p className="pb-3 text-sm">{t("general.phonenumber")}</p>
              <input
                id="phonenumber"
                value={formData.phonenumber}
                type="tel"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phonenumber: e.target.value,
                  })
                }
                placeholder={t("general.phonenumber")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.phonenumber && <p className="mt-1 text-makita-red">{formError.phonenumber}</p>}
            </div>
            <button type="submit" className="btn btn--primary col-span-full" value="registreren">
              {t("general.save")}
              <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default ProfileEdit;
