import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TheLoader from "../../components/TheLoader";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [errors, setErrors] = useState(false);
  const { t } = useTranslation();

  let navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("userData"));

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  // Handle Sumbit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors(false);

    const formDataObject = {
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    };

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}user/account`, formDataObject, axiosConfig)
      .then(function (response) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        userData = response.data.data;
        setLoading(false);
        updateSuccess(response.data.message);
        navigate("/profiel");
      })
      .catch((err) => {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }

        toast(t("error.something-went-wrong"), {
          position: "top-center",

          type: "error",
        });
        setLoading(false);
      });
  };

  // OnFirstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setFormData({
        ...formData,
      });

      setFirstLoad(true);
    }

    return undefined;
  }, []);

  // toastify
  const updateSuccess = (message) =>
    toast.success(message ? message : t("profile.password-updated"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("profile.login-data")}>
        <form onSubmit={handleSubmit}>
          <div className="flex w-full flex-col gap-y-6">
            <div>
              <p className="pb-3 text-sm">{t("general.email-address")}</p>
              {userData.email}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.password")}</p>
              <input
                id="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
                placeholder={t("general.password")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
                type="password"
              />
              {errors && errors.password && (
                <ul className={errors.password.length > 1 ? "list-disc pl-5" : "list-none"}>
                  {errors.password.map((error, id) => (
                    <li key={id} className="text-sm text-makita-red">
                      {error}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.repeat-password")}</p>
              <input
                id="password_confirmation"
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                placeholder={t("general.password")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
                type="password"
                required
              />
              {errors && errors.password_confirmation && (
                <ul className={errors.password_confirmation.length > 1 ? "list-disc pl-5" : "list-none"}>
                  {errors.password_confirmation.map((error, id) => (
                    <li key={id} className="text-sm text-makita-red">
                      {error}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <button type="submit" className="btn btn--primary mt-3" value="registreren">
              {t("general.save")}
              <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default ChangePassword;
