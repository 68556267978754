import React from "react";
import { Navigate } from "react-router-dom";

export const AuthRoute = ({ children }) => {
  // Parse the 'userAuth' from localStorage
  const userAuth = localStorage.getItem("userAuth") || null;

  // If user is not authenticated, redirect to the home page
  if (!userAuth) {
    return <Navigate to="/" replace />;
  }

  // If authenticated, render the children components
  return children;
};
