import React from "react";

const PlatformProductCard = ({ article }) => {
  return (
    <>
      <div
        className="dark:glass grid grid-cols-3 rounded-xl bg-white pr-6 shadow-md dark:!backdrop-blur-none"
        key={article.article_number}
      >
        <div className="col-span-1">
          <div className="flex h-full items-center justify-center rounded-l-xl bg-white p-3">
            <img className="aspect-square h-20 object-contain" src={article.image} alt={article.name} />
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-1.5 py-6 pl-4">
          <p className="font-robotoslab font-bold text-primary dark:text-primary-light">{article.article_number}</p>
          <span className="font-robotoslab text-sm">{article.name}</span>
          {/* TODO: Add description when supplied */}
        </div>
      </div>
    </>
  );
};

export default PlatformProductCard;
