import React from "react";
import Products from "../../components/shop/Products";
import Layout from "../../components/Layout";

const Shop = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  let guestAccount = false;
  if (!userData) {
    guestAccount = true;
  }

  return (
    <div>
      <Layout hideTopNav>
        <Products guestAccount={guestAccount} />
      </Layout>
    </div>
  );
};

export default Shop;
