import React from "react";

const TheLoader = ({ isLoading }) => {
  return (
    <div
      className={`bg-nav dark:bg-nav-dark fixed left-0 top-0 z-[49] flex h-dvh w-dvw items-center justify-center backdrop-blur-xl transition-all duration-300 ${isLoading ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"}`}
      aria-live="polite"
    >
      <div className="loader"></div>
    </div>
  );
};

export default TheLoader;
