import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const ProfileCard = ({ title, linkTo, children }) => {
  return (
    <Link to={linkTo}>
      <div className="dark:glass overflow-hidden rounded-xl bg-white shadow-md">
        <main className="p-3">
          <div className="mb-3 flex items-center justify-between">
            <h2 className="font-bold text-primary dark:text-primary-light">{title}</h2>
            <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" className="text-primary dark:text-primary-light" />
          </div>
          <div className="text-sm">{children}</div>
        </main>
      </div>
    </Link>
  );
};

export default ProfileCard;
