/* eslint-disable no-unused-vars */
import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import TheLoader from "../../components/TheLoader";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ProfileCard from "../../components/cards/ProfileCard";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThemeSwitcher from "../../components/ThemeSwitcher";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [formData, setFormData] = useState("");
  const [branches, setBranches] = useState("");
  const [countries, setCountries] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  const handleLogout = (e) => {
    e.preventDefault();
    const registrationData = localStorage.getItem("registrationData");

    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}logout`)
      .then(function (response) {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("registrationData", registrationData);
        navigate("../", { replace: true });
        setLoading(false);
        return response;
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("registrationData", registrationData);
          navigate("../", { replace: true });
          setLoading(false);
        } else {
          toast(t("error.something-went-wrong"), {
            position: "top-center",

            type: "error",
          });
          setLoading(false);
        }
        setLoading(false);
      });
  };

  // OnFirstLoad
  useEffect(() => {
    if (firstLoad === false) {
      axios
        .get(`${process.env.REACT_APP_API_URL}branch`)
        .then((res) => {
          setBranches(res.data.data);
        })
        .catch((err) => {
          toast(t("error.something-went-wrong"), {
            position: "top-center",

            type: "error",
          });
        });

      fetchCountryData();

      setFormData({
        ...formData,
        avatar: userData.avatar,
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        phonenumber: userData.phonenumber,
        email: userData.email,
        gender: userData.gender,
        country_id: userData.address.country_id,
        housenumber: userData.address.housenumber,
        street: userData.address.street,
        postal: userData.address.postal,
        city: userData.address.city,
      });

      setFirstLoad(true);
    }
  }, [
    firstLoad,
    formData,
    userData.address.city,
    userData.address.country_id,
    userData.address.housenumber,
    userData.address.postal,
    userData.address.street,
    userData.avatar,
    userData.email,
    userData.first_name,
    userData.gender,
    userData.last_name,
    userData.middle_name,
    userData.phonenumber,
  ]);

  const fetchCountryData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((err) => {
        toast(t("error.something-went-wrong"), {
          type: "error",
          position: "top-center",
        });
        return err;
      });
  };

  // toastify
  const updateSucces = (message) =>
    toast.success(message ? message : t("profile.profile-updated"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const deleteProfile = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}user/delete`)
      .then((response) => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("../", { replace: true });
        setLoading(false);
      })
      .catch(() => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("../", { replace: true });
        setLoading(false);
      });
  };

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("profile.title")}>
        <span className="font-robotoslab font-bold text-primary dark:text-primary-light">{t("profile.jump-to")}</span>
        <div className="mb-6 mt-4 flex flex-col gap-x-4 gap-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Button to="/actieregistratie/registraties" icon="tags" text="Actie registraties" arrow />
            <Button to="/garantieregistratie/registraties" icon="toolbox" text="Garantie registraties" arrow />
            <Button to="/cadeauwinkel/bestellingen" icon="shopping-bag" text="Cadeauwinkel bestellingen" arrow />
          </div>
          <hr className="my-3 border-line" />
          <ThemeSwitcher />
          <hr className="border-line" />
        </div>
        <div className="grid grid-cols-5 gap-4 md:grid-cols-3">
          <div className="relative col-span-full flex items-center gap-x-3">
            {!userData.avatar && (
              <img
                src="https://media.istockphoto.com/vectors/default-avatar-photo-placeholder-icon-grey-profile-picture-business-vector-id1327592449?k=20&m=1327592449&s=612x612&w=0&h=6yFQPGaxmMLgoEKibnVSRIEnnBgelAeIAf8FqpLBNww="
                alt="profile-pic"
                className="size-10 rounded-full bg-primary"
              />
            )}
            {userData.avatar && (
              <img
                src={`${userData.avatar}?token=${localStorage.getItem("userAuth")}`}
                alt="profile-pic"
                className="size-10 rounded-full bg-primary"
              />
            )}
            <span className="font-bold text-primary dark:text-primary-light">{t("profile.edit-profile")}</span>
          </div>
        </div>

        <div className="my-3 grid grid-cols-1 gap-3 md:grid-cols-2">
          <ProfileCard title={t("profile.profile-data")} linkTo="./profielgegevens-aanpassen">
            <div className="flex items-center justify-between">
              <span className="block font-semibold">{t("profile.type")}</span>
              {userData.is_company ? t("general.professional") : t("general.private")}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.gender")}</strong>
              {userData.gender === "M" && t("general.male")}
              {userData.gender === "F" && t("general.female")}
              {userData.gender === "X" && t("general.other")}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.first-name")}</strong>
              {userData.first_name}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.last-name")}</strong>
              {userData.middle_name && `${userData.middle_name} `}
              {userData.last_name}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.phonenumber")}</strong>
              {userData.phonenumber}
            </div>
          </ProfileCard>

          <ProfileCard title={t("profile.address-data")} linkTo="./adress-aanpassen">
            <div className="flex items-center justify-between">
              <strong className="block">{t("general.country")}</strong>
              {t(`countries.${userData.address.country.code}`)}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.streetname")}</strong>
              {userData.address.street}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.housenumber")}</strong>
              {userData.address.housenumber}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.zip-code")}</strong>
              {userData.address.postal}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.residence")}</strong>
              {userData.address.city}
            </div>
          </ProfileCard>

          <ProfileCard title={t("profile.login-data")} linkTo="./wachtwoord-wijzigen">
            <div className="flex items-center justify-between">
              <strong className="block">{t("general.email-address")}</strong>
              {userData.email}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <strong className="block">{t("general.password")}</strong>
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </div>
          </ProfileCard>
        </div>

        <Button to="../voorkeur" icon="compass" text={t("profile.edit-aoi")} arrow />
        <button
          onClick={handleLogout}
          className="mt-3 flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold shadow-md dark:bg-primary"
        >
          <FontAwesomeIcon icon="fa-regular fa-right-from-bracket" />
          <span>{t("general.logout")}</span>
        </button>
        <div
          onClick={() => setIsModalOpen(true)}
          className="mt-6 cursor-pointer text-right text-sm text-primary opacity-80 hover:text-primary-light"
        >
          {t("profile.delete-profile")}
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg dark:bg-secondary-dark">
              {/* Modal Header */}
              <div className="flex items-center justify-between pb-3">
                <h3 className="text-lg font-semibold text-black dark:text-white">{t("profile.delete-popup.title")}</h3>
                <button className="text-gray-400 hover:text-gray-600" onClick={() => setIsModalOpen(false)}>
                  ×
                </button>
              </div>

              {/* Modal Body */}
              <div className="mt-4">
                <p className="text-sm text-black dark:text-gray-300">{t("profile.delete-popup.content")}</p>
              </div>

              {/* Modal Footer */}
              <div className="mt-6 flex justify-end space-x-2">
                <button className="btn btn--secondary" onClick={() => setIsModalOpen(false)}>
                  {t("profile.delete-popup.cancel")}
                </button>
                <button
                  className="btn btn--primary bg-makita-red text-white hover:bg-makita-red/80 dark:bg-makita-red dark:text-white dark:hover:bg-makita-red/80"
                  onClick={() => deleteProfile()}
                >
                  {t("profile.delete-popup.delete")}
                </button>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Profile;
