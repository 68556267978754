import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const NewsCardLarge = ({ feedItem }) => {
  return (
    <>
      <div className="col-span-full lg:col-span-6 lg:h-full">
        <Link to={`/discover/item`} state={feedItem}>
          <div className="dark:glass overflow-hidden rounded-xl bg-white shadow-md lg:h-full">
            <img src={feedItem.image_url} alt={feedItem.title} className="aspect-[16/9] w-full object-cover" />
            <div className="px-4 py-6">
              <h3 className="font-bold">{feedItem.title}</h3>
              <p className="mt-1 text-sm font-light text-primary dark:text-white">
                {moment(feedItem.publish_date).format("D-M-Y")}
              </p>
              <p className="mt-1 text-sm">{feedItem.short_description}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default NewsCardLarge;
