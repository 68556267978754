import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import formatDate from "../composable/FormatDate";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const StatusBar = (props) => {
  const { t } = useTranslation();

  return (
    <div className="relative z-10 flex flex-col gap-y-4">
      <div className={`relative flex w-full ${props.statuses.length > 1 ? "justify-between" : "justify-center"}`}>
        <div className="relative flex w-full items-center justify-between">
          <div className="absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-primary"></div>

          {props.statuses.toReversed().map((status) => (
            <div className="relative flex flex-col items-center" key={status.code}>
              <div
                className={`relative z-[1] flex size-10 items-center justify-center rounded-full ${
                  status.code === "999" || status.code === "500" ? "bg-makita-red dark:bg-makita-red" : "bg-primary"
                }`}
              >
                <FontAwesomeIcon
                  icon={
                    status.code === "999"
                      ? "fa-regular fa-xmark"
                      : status.code === "500"
                        ? "fa-regular fa-question"
                        : status.code === "100"
                          ? "fa-regular fa-clock"
                          : status.code === "400"
                            ? "fa-truck fa-regular"
                            : "fa-regular fa-check"
                  }
                  className="text-xl text-white"
                  title={`${formatDate(status.created_at)} - ${status.name}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {!props.hideText && (
        <div className="mb-5">
          <div className="text-center">{t(`status.${props.statuses[0].name.toLowerCase()}`)}</div>
          {props.statuses[0].reason && (
            <div className="mt-3 text-center italic">
              <strong>{t(`status.reason`)}: </strong>
              {props.statuses[0].reason}
            </div>
          )}
          {props.statuses[0].code === "500" && (
            <div className="mt-4 flex justify-center">
              <Link to="./bewerken">
                <button className="btn btn--primary">
                  {t("action-registration.edit-registration")}
                  <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                </button>
              </Link>
            </div>
            // <Link to={`./${registration.id}`} state={{ registration: registration }} key={registration.id}>
            //
            // </Link>
            // <a
            //   className="dark:glass flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
            //   href={`${serial.serialnumber_image}?token=${localStorage.getItem("userAuth")}`}
            //   rel="noopener noreferrer nofollow"
            //   target="_blank"
            // >
            //   <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
            //   {t("general.serialnumber")}
            // </a>
          )}
        </div>
      )}
    </div>
  );
};

export default StatusBar;
