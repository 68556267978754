import { useTheme } from "../composable/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const ThemeSwitcher = () => {
  const { theme, setLightMode, setDarkMode, setAutoMode } = useTheme();
  const { t } = useTranslation();

  const activeButtonClasses = "btn--primary";
  const inactiveButtonClasses = "btn--secondary";

  const lightButtonClass = theme === "light" ? activeButtonClasses : inactiveButtonClasses;
  const darkButtonClass = theme === "dark" ? activeButtonClasses : inactiveButtonClasses;
  const autoButtonClass = theme === "auto" ? activeButtonClasses : inactiveButtonClasses;

  return (
    <>
      <div>
        <span className="font-robotoslab font-bold text-primary dark:text-primary-light">{t("general.theme")}</span>
        <div className="mb-6 mt-4 flex gap-x-3">
          <button onClick={setLightMode} className={`btn w-full ${lightButtonClass}`}>
            <div className="flex items-center justify-start gap-2">
              <FontAwesomeIcon icon="fa-regular fa-sun" />
              {t("general.light")}
            </div>
          </button>
          <button onClick={setDarkMode} className={`btn w-full ${darkButtonClass}`}>
            <div className="flex items-center justify-start gap-2">
              <FontAwesomeIcon icon="fa-regular fa-moon" />
              {t("general.dark")}
            </div>
          </button>
          <button onClick={setAutoMode} className={`btn w-full ${autoButtonClass}`}>
            <div className="flex items-center justify-start gap-2">
              <FontAwesomeIcon icon="fa-regular fa-mobile" className="block lg:hidden" />
              <FontAwesomeIcon icon="fa-regular fa-display" className="hidden lg:block" />
              {t("general.system")}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default ThemeSwitcher;
