import Compressor from "compressorjs";

async function CompressImage(image) {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.7,
      maxHeight: 1920,
      maxWidth: 1920,
      success: (result) => {
        resolve(
          new File([result], image.name, {
            type: result.type,
          }),
        );
      },
      error: reject,
    });
  });
}

export default CompressImage;
