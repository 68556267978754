import React from "react";

import { Link } from "react-router-dom";

const PageCardGuest = ({ IMG, Title, Alt, PageLink, wide }) => {
  return (
    <Link to={PageLink}>
      <div className="dark:glass rounded-xl bg-white shadow-md">
        <img
          src={IMG}
          alt={Alt}
          className={`size-full rounded-t-xl object-cover ${wide ? "aspect-[16/9]" : "aspect-[4/3]"}`}
        />
        <div className="p-4">
          <span className="font-robotoslab font-bold">{Title}</span>
        </div>
      </div>
    </Link>
  );
};

export default PageCardGuest;
