import React from "react";

const ErrorMessage = ({ message }) => {
  if (!message) return null;
  if (message.length > 1) {
    return message.map((msg, index) => {
      return (
        <div key={index} className="mt-1 bg-makita-red/10 p-2 text-makita-red">
          {msg}
        </div>
      );
    });
  } else {
    return <div className="mt-1 bg-makita-red/10 p-2 text-makita-red">{message}</div>;
  }
};

export default ErrorMessage;
