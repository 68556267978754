import { StatusBar, Style } from "@capacitor/status-bar";
import { App } from "@capacitor/app";
import { useTheme } from "../composable/ThemeContext";
import { useNavigate } from "react-router-dom";

const CapacitorController = ({ red }) => {
  const { theme } = useTheme();

  let mode = theme;
  if (mode === "auto") {
    mode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  // Back Button
  //BackButton control
  const navigate = useNavigate();

  function handleBackButton() {
    if (sessionStorage.getItem("triggerBackbutton")) {
      return;
    }

    sessionStorage.setItem("triggerBackbutton", true);

    const url = window.location.pathname;

    if (url === "/" || url === "/home") {
      sessionStorage.removeItem("triggerBackbutton");
      App.exitApp();
      return;
    }

    navigate(-1);

    setTimeout(() => {
      sessionStorage.removeItem("triggerBackbutton");
    }, 500);
  }

  // Remove existing back button listeners and add the new one
  App.removeAllListeners().then(() => {
    App.addListener("backButton", handleBackButton);
  });

  // Ensure that the listener is added on page load or initialization
  document.addEventListener("DOMContentLoaded", () => {
    App.addListener("backButton", handleBackButton);
  });

  // Statusbar
  try {
    // Set navbar color
    if (red) {
      StatusBar.setBackgroundColor({ color: "#c20016" }).catch(() => {});
      StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
    } else {
      if (mode === "light") {
        StatusBar.setBackgroundColor({ color: "#f4f4f4" }).catch(() => {});
        StatusBar.setStyle({ style: Style.Light }).catch(() => {});
      } else {
        StatusBar.setBackgroundColor({ color: "#0a0a0a" }).catch(() => {});
        StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
      }
    }

    // Set overlay
    StatusBar.setOverlaysWebView({ overlay: false }).catch(() => {});
    StatusBar.show({ AnimationOptions: "FADE" }).catch(() => {});

    return null;
  } catch (error) {
    return error;
  }
};

export default CapacitorController;
