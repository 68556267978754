import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const CustomSelect = ({
  id,
  placeholder,
  options,
  defaultValue = null,
  onChange,
  noOptionsMessage = null,
  isDisabled = false,
  secondary = false,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      id={id}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      noOptionsMessage={() => noOptionsMessage ?? t("general.no-options-available")}
      classNames={{
        control: (state) =>
          `rounded-full border-none px-4 py-2.5 text-sm shadow-md ${secondary && "bg-white dark:bg-secondary-dark"} ${state.isDisabled ? "bg-button-inactive dark:bg-button-inactive-dark" : "bg-white dark:glass"}`,
        menu: () => "bg-white text-sm rounded-xl px-4 py-2 mt-2 dark:glass",
        option: () => "py-1",
      }}
      unstyled
      isDisabled={isDisabled}
    />
  );
};
export default CustomSelect;
