import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TheLoader from "../../components/TheLoader";
import CustomSelect from "../../components/CustomSelect";

const AddressEdit = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const [countries, setCountries] = useState("");
  const [formError, setError] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const { t } = useTranslation();

  let navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("userData"));

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  // Handle Sumbit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataObject = {
      street: formData.street,
      city: formData.city,
      postal: formData.postal,
      housenumber: formData.housenumber,
      country_id: formData.country_id,
    };

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}user/address`, formDataObject, axiosConfig)
      .then(function (response) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        userData = response.data.data;
        setLoading(false);
        updateSuccess(response.data.message);
        navigate("/profiel");
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setError(error.response.data.errors);
        } else {
          toast(t("error.something-went-wrong"), {
            position: "top-center",

            type: "error",
          });
        }
        setLoading(false);
      });
  };

  // zipcode api
  useEffect(() => {
    if (formData.housenumber === undefined || formData.housenumber.length < 1) return;
    if (formData.postal === undefined || formData.postal.length < 6) return;

    let item = {
      zipcode: formData.postal,
      housenumber: formData.housenumber,
    };

    axios.post(`${process.env.REACT_APP_API_URL}zipcode`, item).then((res) => {
      setFormData({ ...formData, city: res.data.city, street: res.data.street });
    });
    return undefined;
  }, []);

  // OnFirstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}country`, axiosConfig).then((response) => {
        setCountries(response.data.data);
        setLoading(false);
      });

      setFormData({
        ...formData,
        street: userData.address.street,
        city: userData.address.city,
        postal: userData.address.postal,
        housenumber: userData.address.housenumber,
        country_id: userData.address.country.id,
      });

      setFirstLoad(true);
    }

    return undefined;
  }, [
    axiosConfig,
    firstLoad,
    formData,
    userData.address.city,
    userData.address.country.id,
    userData.address.housenumber,
    userData.address.postal,
    userData.address.street,
  ]);

  // toastify
  const updateSuccess = (message) =>
    toast.success(message ? message : t("profile.profile-updated"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <TheLoader isLoading={loading} />
      <Layout Title={t("profile.profile-data")}>
        <form onSubmit={handleSubmit}>
          <div className="grid w-full grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-4">
            {countries && (
              <div>
                <p className="pb-3 text-sm">{t("general.country")}</p>
                <CustomSelect
                  id="country_id"
                  placeholder={t("general.country")}
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  defaultValue={countries
                    .filter((country) => country.id === formData.country_id)
                    .map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                  onChange={({ value }) =>
                    setFormData({
                      ...formData,
                      country_id: value,
                    })
                  }
                />
                {formError.country_id && <p className="mt-1 text-makita-red">{formError.country_id}</p>}
              </div>
            )}
            <div>
              <p className="pb-3 text-sm">{t("general.zip-code")}</p>
              <input
                id="postal"
                value={formData.postal}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    postal: e.target.value,
                  })
                }
                placeholder={t("general.zip-code")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.postal && <p className="mt-1 text-makita-red">{formError.postal}</p>}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.housenumber")}</p>
              <input
                id="housenumber"
                value={formData.housenumber}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    housenumber: e.target.value,
                  })
                }
                placeholder={t("general.housenumber")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />

              {formError.housenumber && <p className="mt-1 text-makita-red">{formError.housenumber}</p>}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.streetname")}</p>
              <input
                id="street"
                value={formData.street}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    street: e.target.value,
                  })
                }
                placeholder={t("general.streetname")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.street && <p className="mt-1 text-makita-red">{formError.street}</p>}
            </div>
            <div>
              <p className="pb-3 text-sm">{t("general.residence")}</p>
              <input
                id="city"
                value={formData.city}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    city: e.target.value,
                  })
                }
                placeholder={t("general.residence")}
                className="dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md"
              />
              {formError.city && <p className="mt-1 text-makita-red">{formError.city}</p>}
            </div>

            <button type="submit" className="btn btn--primary col-span-full" value="registreren">
              {t("general.save")}
              <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddressEdit;
