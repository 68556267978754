import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import TheLoader from "../../components/TheLoader";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AreaOfInterest = () => {
  const [loading, setLoading] = useState(false);
  const [interests, setInterests] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userInterests, setUserInterests] = useState();
  const [firstLoad, setFirstLoad] = useState(false);
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // Get AreaOfInterests
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}user/area-of-interest`)
        .then((res) => {
          setInterests(res.data.areasOfInterest);
          setFirstLoad(true);
          setUserInterests(userData.areasOfInterest);
          setLoading(false);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, []);

  const handleInterest = async (name) => {
    try {
      setLoading(true);
      // If interest is in the localStorage
      if (userData.areas_of_interest.indexOf(name) > -1) {
        // Remove interest
        let NewInterests = Object.values(userData.areas_of_interest);
        let index = NewInterests.indexOf(name);
        if (index > -1) {
          NewInterests.splice(index, 1);
        }
        let interestsArray = Object.values(NewInterests);

        axios
          .put(`${process.env.REACT_APP_API_URL}user/area-of-interest`, {
            areas_of_interest: interestsArray,
          })
          .then(function (response) {
            localStorage.setItem("userData", JSON.stringify(response.data.user));
            setFirstLoad(false);
            setLoading(false);
          })
          .catch(function (error) {
            throw new Error(error);
          });
      } else {
        // Add interest
        const NewInterests = { ...userData.areas_of_interest, name };
        let interestsArray = Object.values(NewInterests);

        axios
          .put(`${process.env.REACT_APP_API_URL}user/area-of-interest`, {
            areas_of_interest: interestsArray,
          })
          .then(function (response) {
            localStorage.setItem("userData", JSON.stringify(response.data.user));
            setFirstLoad(false);
            setLoading(false);
          })
          .catch(function (error) {
            throw new Error(error);
          });
      }
    } catch (error) {
      throw new Error(error);
    }
    sessionStorage.removeItem("DiscoverFeed");
    sessionStorage.removeItem("DiscoverItems");
  };

  return (
    <>
      {/* TheLoader */}
      <TheLoader isLoading={loading} />
      <Layout Title={t("profile.aoi-title")}>
        <div className="mt-8 lg:mt-0">
          <p className="text-sm">{t("profile.aoi-introduction")}</p>
          <hr className="my-6 border-line" />

          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            {interests.map((interest) => (
              <>
                <div
                  className={`card relative aspect-square w-full cursor-pointer overflow-hidden rounded-xl md:h-40`}
                  onClick={() => handleInterest(interest.name)}
                >
                  <img
                    className={`h-full w-full object-cover ${
                      userData.areas_of_interest.indexOf(interest.name) > -1 ? "" : "grayscale"
                    } `}
                    src={interest.image_url}
                    alt={interest.name}
                  />

                  <div className="absolute bottom-0 left-0 w-full">
                    <div
                      className={`w-full p-3 text-sm font-bold  opacity-100 ${
                        userData.areas_of_interest.indexOf(interest.name) > -1
                          ? "bg-primary text-white"
                          : "bg-[#CCE6EA] dark:bg-primary-light"
                      }`}
                    >
                      {interest.name}
                    </div>
                  </div>

                  {userData.areas_of_interest.indexOf(interest.name) > -1 && (
                    <div className="absolute right-2 top-2">
                      <div className="flex size-[30px] items-center justify-center rounded-full bg-primary text-white shadow-lg dark:bg-primary-light dark:text-content-dark">
                        <FontAwesomeIcon icon="fa-regular fa-check" className="text-sm" />
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AreaOfInterest;
