import React from "react";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDate from "../../composable/FormatDate";

const DetailWarrantyRegistration = () => {
  const location = useLocation();
  const data = location.state;
  const { t } = useTranslation();

  return (
    <Layout Title={t("warranty-registration.warranty")}>
      <div className="mt-8">
        {data.article !== undefined || data.article !== null ? (
          <>
            <div className="grid grid-cols-3">
              <div className="col-span-1">
                <div className="flex h-full justify-center">
                  {data.article.image && (
                    <img
                      src={data.article.image}
                      alt={data.serialnumber}
                      className="h-full max-h-20 w-full object-contain p-3 dark:bg-white"
                    />
                  )}
                </div>
              </div>

              <div className="col-span-2 flex flex-col gap-y-3 pl-4">
                <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
                  {data.article.name}
                </span>

                <div className="flex flex-col gap-y-1">
                  <div className="flex justify-between">
                    <strong>{t("general.registration")}: </strong>
                    <span className="text-right">{formatDate(data.created_at.split("T")[0])}</span>
                  </div>
                  <div className="flex justify-between">
                    <strong>{t("general.serialnumber")}: </strong>
                    <span className="text-right">{data.serialnumber}</span>
                  </div>
                  <div className="flex justify-between">
                    <strong>{t("general.date-of-purchase")}: </strong>
                    <span className="text-right">{formatDate(data.purchase_date)}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-6 border-line" />

            <div className="flex gap-x-4">
              <a
                className="dark:glass flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                href={`${data.serialnumber_image}?token=${localStorage.getItem("userAuth")}`}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                {t("general.serialnumber")}
              </a>
              <a
                className="dark:glass flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                href={`${data.purchase_image}?token=${localStorage.getItem("userAuth")}`}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                {t("general.invoice")}
              </a>
            </div>

            {/* TODO: status inbouwen als die beschikbaar is */}
          </>
        ) : (
          <span>{t("error.no-data-found")}</span>
        )}
      </div>
    </Layout>
  );
};

export default DetailWarrantyRegistration;
