import React from "react";

import ImageCard from "./ImageCard";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Image imports
import Photo from "../../img/Nieuwsbrief.webp";
import axios from "axios";
import { toast } from "react-toastify";

const NewsletterCard = () => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}newsletter`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t("newsletter.subscribe-success"), {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        } else if (res.status === 400) {
          toast.error(t("newsletter.already-subscribed"), {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err && err.response.data.message) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const { t } = useTranslation();

  return (
    <ImageCard IMG={Photo}>
      <span className="font-robotoslab font-semibold">{t("newsletter.title")}</span>

      <form className="mt-3" onSubmit={handleSubmit}>
        <div className="privacy-statement">
          <input type="checkbox" required />
          <span className="relative w-full pl-4 text-sm">
            <Trans
              i18nKey="general.privacy-policy-consent"
              components={{
                link1: (
                  <a
                    href={process.env.REACT_APP_PRIVACY_POLICY}
                    target="_blank"
                    rel="nofollow noreferrer"
                    className="animate cursor-pointer text-primary underline hover:opacity-50 dark:text-primary-light"
                  />
                ),
              }}
            />
          </span>
        </div>
        <button className="btn btn--primary mt-3">
          {t("newsletter.subscribe")}
          <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
        </button>
      </form>
    </ImageCard>
  );
};

export default NewsletterCard;
