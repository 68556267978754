import React from "react";
import Nav from "../components/navbar/Nav";
import { useRef, useEffect, useState } from "react";
import LandingspageNavbar from "../components/navbar/LandingpageNavbar";
import { useTranslation } from "react-i18next";
import CapacitorController from "../components/CapacitorController";
import iOS from "../composable/ios";
import Newsletter from "../components/cards/NewsletterCard";
import ImageCard from "../components/cards/ImageCard";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

// IMG Imports
import PointsCard from "../components/cards/PointsCard";
import PageCardGuest from "../components/cards/PageCardGuest";
import axios from "axios";
import InstagramCard from "../components/cards/InstagramCard";
import GetSession from "../composable/GetSession";
import SetSession from "../composable/SetSession";
import Logo from "../img/logos/Makita_Logo_Transparant.svg";
import Platform from "../img/platform.webp";
import Cadeauwinkel from "../img/cadeauwinkel.webp";
import Contact from "../img/makita-pand.webp";
import Actie from "../img/Actie.webp";
import Discover from "../img/Discover.webp";
import { Link } from "react-router-dom";

const Home = () => {
  const startup = useRef();

  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const registrationData = JSON.parse(localStorage.getItem("registrationData"));
  const [firstLoad, setFirstLoad] = useState(false);
  const [instaPost, setInstaPost] = useState(false);
  const [homeTiles, setHomeTiles] = useState(false);
  const [points, setPoints] = useState("Laden...");

  useEffect(() => {
    if (firstLoad === false) {
      if (GetSession("InstagramPost") !== null) {
        setInstaPost(GetSession("InstagramPost"));
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}instagram/latest`).then((res) => {
          SetSession("InstagramPost", res.data[0], 10);
          setInstaPost(res.data[0]);
        });
      }

      if (GetSession("HomeTiles") !== null) {
        setHomeTiles(GetSession("HomeTiles"));
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}home-tiles`).then(({ data }) => {
          SetSession("HomeTiles", data.data);
          setHomeTiles(data.data);
        });
      }

      setFirstLoad(true);
    }
    if (registrationData) {
      toast(t("action-registration.incomplete-registration"), {
        type: "warning",
        position: "top-center",
      });
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      adapter: "jsonAdapter",
    };
    axios.get(`${process.env.REACT_APP_API_URL}user/@me`).then((res) => {
      if (localStorage.getItem("userAuth") !== undefined) {
        setPoints(res.data.data.points);
      } else {
        setTimeout(() => {
          setPoints("0");
        }, 2000);
      }
    });
  }, []);

  return (
    <>
      <div ref={startup}>
        <div
          className={`fixed z-[9999] flex h-full w-full items-center justify-center bg-makita-red p-20 md:p-40 ${
            sessionStorage.getItem("appLoaded") ? "hidden" : ""
          }`}
        >
          <img src={Logo} className="w-full" id="animation-img" alt="logo" />
        </div>
      </div>

      <div className="grid grid-cols-12">
        <div className="z-[9999]">
          <LandingspageNavbar authenticated />
        </div>

        <div className={` default-cols px-4 pb-28 pt-36 ${iOS() ? "md:pt-40" : "md:pt-48"}`}>
          <div className="col-span-full flex flex-col gap-y-6">
            <h3 className="font-robotoslab text-2xl font-semibold lg:text-3xl">
              <span className="font-normal">{t("general.welcome-back")}</span> <br />
              {userData.first_name} {userData.last_name}
            </h3>
            <div className="flex flex-col gap-y-4">
              <PointsCard points={points} />
            </div>
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-4">
              <PageCardGuest
                Title={t("action-registration.title")}
                IMG={Actie}
                Alt={t("action-registration.page-card-alt")}
                PageLink="/actieregistratie"
              />
              <PageCardGuest
                Title={t("gift-shop.title")}
                IMG={Cadeauwinkel}
                Alt={t("gift-shop.page-card-alt")}
                PageLink="/cadeauwinkel"
              />
              <PageCardGuest
                Title={t("platform-help.title")}
                IMG={Platform}
                Alt={t("platform-help.page-card-alt")}
                PageLink="/platform"
              />
              <PageCardGuest
                Title={t("discover.title")}
                IMG={Discover}
                Alt={t("discover.page-card-alt")}
                PageLink="/discover"
              />
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              <InstagramCard post={instaPost} />
              <Newsletter />

              {homeTiles.length > 0 &&
                homeTiles.map((tile, index) => (
                  <Link key={index} to={tile.url}>
                    <ImageCard IMG={tile.image}>
                      <div className="font-robotoslab font-semibold">{tile.title}</div>
                      <div className="mt-3 text-sm">{tile.description}</div>
                    </ImageCard>
                  </Link>
                ))}

              <div className="col-span-full">
                <ImageCard IMG={Contact} mdSmall>
                  <div className="flex flex-col gap-3">
                    <h4 className="font-robotoslab font-semibold">{t("general.need-help")}</h4>
                    <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                      <div className="w-full">
                        <h4 className="font-robotoslab font-semibold">{t("general.promo-questions")}</h4>
                        <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                          <p className="w-full">
                            <Trans
                              i18nKey="general.promo-questions-text"
                              components={{
                                link1: (
                                  <a
                                    href="tel:0031404400555"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="animate hover:opacity-50"
                                  />
                                ),
                                link2: (
                                  <a
                                    href="mailto:actie@makita.nl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="animate hover:opacity-50"
                                  />
                                ),
                              }}
                            ></Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ImageCard>
              </div>

              <div className="col-span-full">
                <div className="dark:glass flex flex-col justify-between rounded-xl bg-white shadow-md p-6">
                  <div className="flex flex-col md:flex-row md:justify-between gap-y-4 md:gap-x-4">
                    <div className="w-full">
                      <h4 className="font-robotoslab font-semibold">{t("general.product-questions")}</h4>
                      <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                        <p className="w-full">
                          <Trans
                            i18nKey="general.product-questions-text"
                            components={{
                              link1: (
                                <a
                                  href="tel:0031402064040"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="animate hover:opacity-50"
                                />
                              ),
                              link2: (
                                <a
                                  href="mailto:klantenservice@makita.nl"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="animate hover:opacity-50"
                                />
                              ),
                            }}
                          ></Trans>
                        </p>
                      </div>
                    </div>
                    <p className="w-full">
                      <Trans i18nKey="general.need-help-text"></Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Nav />
        <CapacitorController red />
      </div>
    </>
  );
};

export default Home;
