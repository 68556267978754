import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import formatDate from "../../composable/FormatDate";
import { useTranslation } from "react-i18next";
import TheLoader from "../../components/TheLoader";

const OrderHistory = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // On firstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}shop/order`)
        .then((res) => {
          setOrders(res.data.shopOrders);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.loading-orders"), {
            type: "error",

            position: "top-center",
          });
          setLoading(false);
        });

      setFirstLoad(true);
    }
  }, []);

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("general.previous-orders")}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
          {orders.map((order) => {
            return (
              <div key={order.id}>
                <Link to={`./${order.id}`} state={order}>
                  <div className="dark:glass grid h-full grid-cols-3 rounded-xl bg-white pr-6 shadow-md">
                    <div className="col-span-1">
                      <div className="flex h-full items-center justify-center rounded-l-xl bg-white p-4">
                        {order.items[0].article.images && (
                          <img
                            src={order.items[0].article.images[0]}
                            alt={order.items[0].article.name}
                            className="h-full max-h-20 w-full object-contain"
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-span-2 flex flex-col gap-1 py-6 pl-4">
                      <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
                        Order #{order.external_id}
                      </span>

                      {order.items.map((item) => {
                        return (
                          <div key={item.article.id}>
                            <div className="mt-2 flex justify-between text-sm">
                              <strong>{t("general.product")}: </strong>
                              <span className="text-right">{item.article.name}</span>
                            </div>
                            <div className="flex justify-between text-sm">
                              <strong>{t("gift-shop.ordered")}: </strong>
                              <span className="text-right">{formatDate(order.created_at)}</span>
                            </div>
                            <div className="flex justify-between text-sm">
                              <strong>{t("gift-shop.points")}: </strong>
                              <span className="text-right">{item.points}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        {!orders && !loading && <div className="mt-5 px-4 text-center">{t("gift-shop.no-orders-found")}</div>}
      </Layout>
    </>
  );
};

export default OrderHistory;
