import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  // Function to get the current system theme
  const getSystemTheme = () => (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");

  // Retrieve the initial theme from local storage or set to 'auto'
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "auto");

  // Function to apply the theme to the document
  const applyTheme = (theme) => {
    const actualTheme = theme === "auto" ? getSystemTheme() : theme;
    document.documentElement.classList.toggle("dark", actualTheme === "dark");
    document.documentElement.classList.toggle("light", actualTheme !== "dark"); // Optionally manage a 'light' class
  };

  // Effect to listen to changes in system theme
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleSystemThemeChange = () => {
      // Apply system theme only if user preference is set to 'auto'
      if (theme === "auto") {
        applyTheme("auto");
      }
    };

    mediaQuery.addEventListener("change", handleSystemThemeChange);
    return () => mediaQuery.removeEventListener("change", handleSystemThemeChange);
  }, [theme]);

  // Effect to apply the theme whenever it changes
  useEffect(() => {
    applyTheme(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const setLightMode = () => {
    setTheme("light");
  };

  const setDarkMode = () => {
    setTheme("dark");
  };

  const setAutoMode = () => {
    setTheme("auto");
    applyTheme("auto");
  };

  return (
    <ThemeContext.Provider value={{ theme, setLightMode, setDarkMode, setAutoMode }}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
