import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import TheLoader from "../../components/TheLoader";
import PlatformProductCard from "../../components/cards/PlatformProductCard";
import { toast } from "react-toastify";
import { t } from "i18next";
import iOS from "../../composable/ios";
import GetSession from "../../composable/GetSession";
import SetSession from "../../composable/SetSession";
// import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";

const Platform = () => {
  const [filterType, setFilterType] = useState("accu");
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState();
  const [filterArticle, setFilterArticle] = useState();
  const [filterPlatform, setFilterPlatform] = useState();
  const [articles, setArticles] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [showAmount, setShowAmount] = useState(5);

  const handleSelectArticle = ({ value }) => {
    setFilterArticle(value);
    setLoading(true);
  };
  const handleSelectPlatform = ({ value }) => {
    setFilterPlatform(value);
    setLoading(true);
  };

  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      try {
        if (GetSession("PlatformArticles") !== null) {
          setArticles(GetSession("PlatformArticles"));
          setFirstLoad(true);
          setLoading(false);
        } else {
          axios.get(`${process.env.REACT_APP_API_URL}platform-help/articles`).then((res) => {
            setArticles(res.data.data);
            SetSession("PlatformArticles", res.data.data, 1440);
            setFirstLoad(true);
            setLoading(false);
          });
        }
      } catch (error) {
        toast(t("error.data-retrieval"), {
          type: "error",

          position: "top-center",
        });
        setLoading(false);
      }

      try {
        if (GetSession("PlatformPlatforms") !== null) {
          setPlatforms(GetSession("PlatformPlatforms"));
          setFirstLoad(true);
          setLoading(false);
        } else {
          axios.get(`${process.env.REACT_APP_API_URL}platform-help/platforms`).then((res) => {
            setPlatforms(res.data.data);
            SetSession("PlatformPlatforms", res.data.data, 1440);
            setFirstLoad(true);
            setLoading(false);
          });
        }
      } catch (error) {
        toast(t("error.data-retrieval"), {
          type: "error",

          position: "top-center",
        });
        setLoading(false);
      }
    }

    if (window.innerWidth > 1280) {
      setShowAmount(20);
    }
  }, []);

  useEffect(() => {
    if (filterPlatform !== undefined) {
      axios
        .get(`${process.env.REACT_APP_API_URL}platform-help/articles/` + filterPlatform)
        .then((response) => {
          setFilteredArticles(response.data.data);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.data-retrieval"), {
            type: "error",

            position: "top-center",
          });
          setLoading(false);
        });
    }
  }, [filterPlatform]);

  useEffect(() => {
    if (filterArticle !== undefined) {
      axios
        .get(`${process.env.REACT_APP_API_URL}platform-help/platforms/` + filterArticle)
        .then((response) => {
          setFilteredPlatforms(response.data.data);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.data-retrieval"), {
            type: "error",

            position: "top-center",
          });
          setLoading(false);
        });
    }
  }, [filterArticle]);

  return (
    <>
      {/* TheLoader */}
      <TheLoader isLoading={loading} />

      {/* Filter */}
      <div className={`fixed right-4 ${iOS() ? "bottom-24" : "bottom-20"}`}>
        <div className="flex gap-x-1">
          <div
            className={`animate cursor-pointer rounded-xl p-3 font-bold shadow-md ${filterType === "accu" ? "bg-primary text-white dark:bg-primary-light" : "dark:glass bg-button-primary hover:bg-primary-light dark:hover:bg-secondary"}`}
            onClick={() => setFilterType("accu")}
          >
            {t("platform-help.filter-battery")}
          </div>
          <div
            className={`animate cursor-pointer rounded-xl p-3 font-bold shadow-md ${filterType === "product" ? "bg-primary text-white dark:bg-primary-light" : "dark:glass bg-button-primary hover:bg-primary-light dark:hover:bg-secondary"}`}
            onClick={() => setFilterType("product")}
          >
            {t("platform-help.filter-product")}
          </div>
        </div>
      </div>

      {/* Content */}
      <Layout Title={t("platform-help.title")}>
        <div className="platform-help">
          {/* Filter on accu */}
          {filterType === "accu" && (
            <>
              <h2 className="pb-3 text-sm">{t("platform-help.select-battery")}</h2>
              <div className="relative">
                <CustomSelect
                  id="platform-id"
                  placeholder={t("platform-help.select-battery")}
                  options={platforms.map((platform) => ({
                    value: platform.slug,
                    label: platform.name,
                  }))}
                  onChange={handleSelectPlatform}
                />
              </div>
              {/* Cards */}
              <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-2">
                {filteredArticles.length > 0 &&
                  filteredArticles.map((article, index) => {
                    if (index <= showAmount) {
                      return <PlatformProductCard article={article} key={index} />;
                    }
                    return null;
                  })}
              </div>
              {filteredArticles.length > 0 && showAmount < filteredArticles.length && (
                <div className="my-4">
                  <span onClick={() => setShowAmount(showAmount + 6)} className="link text-primary">
                    {t("platform-help.show-more")}
                  </span>
                </div>
              )}

              {filteredArticles.length <= 0 &&
                loading === false &&
                (filterPlatform !== undefined ? (
                  <span>{t("platform-help.no-products-found")}</span>
                ) : (
                  <span>{t("platform-help.select-battery")}</span>
                ))}
            </>
          )}
          {/* Filter on products */}
          {filterType === "product" && (
            <>
              <h2 className="pb-3 text-sm">{t("platform-help.select-product")}</h2>
              <div className="relative">
                <CustomSelect
                  id="article-id"
                  placeholder={t("platform-help.select-product")}
                  options={articles.map((article) => ({
                    value: article.article_id,
                    label: `${article.article_number} - ${article.name}`,
                  }))}
                  onChange={handleSelectArticle}
                />
              </div>

              <div className="mt-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {filteredPlatforms.length > 0 &&
                  filteredPlatforms.map((article, index) => {
                    if (index <= showAmount) {
                      return <PlatformProductCard article={article} key={index} />;
                    }
                    return null;
                  })}
              </div>
              {filteredPlatforms.length > 0 && showAmount < filteredPlatforms.length && (
                <div className="mt-4">
                  <span onClick={() => setShowAmount(showAmount + 6)} className="link text-primary">
                    {t("platform-help.show-more")}
                  </span>
                </div>
              )}
              {filteredPlatforms.length <= 0 &&
                loading === false &&
                (filterArticle !== undefined ? (
                  <span>{t("platform-help.no-products-found")}</span>
                ) : (
                  <span>{t("platform-help.select-product")}</span>
                ))}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Platform;
