import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import InputMask from "react-input-mask";
import GetSession from "../../composable/GetSession";
import SetSession from "../../composable/SetSession";
import setSession from "../../composable/SetSession";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompressImage from "../../composable/CompressImage";
import TheLoader from "../../components/TheLoader";
import CustomSelect from "../../components/CustomSelect";

const ProductRegistrationEdit = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [registration, setRegistration] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setError] = useState({ status: null });
  const [dealers, setDealers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState([]);
  const [promotionsController, setPromotionsController] = useState([]);
  const [countries, setCountries] = useState([]);
  const [serieNummer, setSerieNummer] = useState([]);
  const [factuur, setFactuur] = useState([]);
  const [factuurName, setFactuurName] = useState(null);
  const [serieNummerName, setSerieNummerName] = useState(null);
  const [selectedGift, setSelectedGift] = useState(null);
  const [actionCodeImage, setActionCodeImage] = useState([]);
  const [actionCodeName, setActionCodeName] = useState(null);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    adapter: "jsonAdapter",
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  const updateSucces = () =>
    toast.success(t("action-registration.updated-successfully"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => navigate("/actieregistratie/registraties"),
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let formDataArray = new FormData();
    formDataArray.append("_method", "PUT");
    formDataArray.append("article_id", formData.article_id.article_id);
    formDataArray.append("product_amount", 1);
    formDataArray.append("dealer_id", formData.dealer_id);
    formDataArray.append("purchase_date", formData.purchase_date);
    formDataArray.append("promotion_id", formData.promotion_id);
    formDataArray.append("terms_and_conditions", formData.terms_and_conditions);
    formDataArray.append("gift", formData.gift);
    formDataArray.append("zipcode", formData.zipcode);
    formDataArray.append("housenumber", formData.housenumber);
    formDataArray.append("streetname", formData.streetname);
    formDataArray.append("city", formData.city);
    formDataArray.append("country_id", formData.country_id);

    formData.serialnumberids.map((item) => formDataArray.append("serialnumberids[]", item));

    if (serieNummer && serieNummer.length > 0) {
      serieNummer
        .sort((a, b) => a.id - b.id)
        .map((item) => {
          formDataArray.append(`serialnumber_image[${item.id}]`, item.image.url);
        });
    }

    if (serialNumbers && serialNumbers.length > 0) {
      serialNumbers
        .sort((a, b) => a.id - b.id)
        .map((item) => {
          formDataArray.append(`serialnumbers[${item.id}][serialnumber]`, item.serialnumber);
          if (item.article_id) {
            formDataArray.append(`serialnumbers[${item.id}][article_id]`, item.article_id);
          }
        });
    }

    if (factuur.url) {
      formDataArray.append("purchase_image", factuur.url);
    }

    if (registration.promotion.type === 1) {
      formDataArray.append("iban", formData.iban);
      formDataArray.append("promo_code", formData.promo_code);
      if (actionCodeImage.url) {
        formDataArray.append("action_code_image", actionCodeImage.url);
      }
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}registration/${registration.id}`, formDataArray, axiosConfig)
      .then(function () {
        setLoading(false);
        updateSucces();
      })
      .catch(function (error) {
        setLoading(false);
        setError(error.response.data.errors);
        toast(t("error.invalid-data"), {
          position: "top-center",
          type: "error",
        });
      });
    setLoading(false);
  };

  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      if (GetSession("products") !== null) {
        setProducts(GetSession("products"));
        setLoading(false);
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}article?warranty-available=true`)
          .then((response) => {
            setProducts(response.data.data);
            SetSession("products", response.data.data);
            setLoading(false);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      if (GetSession("dealers") !== null) {
        setDealers(GetSession("dealers"));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}dealer`)
          .then((response) => {
            setDealers(response.data.data);
            SetSession("dealers", response.data.data);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      if (GetSession("countries") !== null) {
        setCountries(GetSession("countries"));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}country`)
          .then((response) => {
            setCountries(response.data.data);
            setSession("countries", response.data.data, 1440);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      setFormData({
        ...formData,
        zipcode: userData.address.postal,
        housenumber: userData.address.housenumber,
        streetname: userData.address.street,
        city: userData.address.city,
        country_id: userData.address.country.id,
      });

      if (state.state === undefined || state.state === null) {
        const id = window.location.pathname.split("/")[3];
        axios.get(`${process.env.REACT_APP_API_URL}registration/${id}`).then(({ data }) => {
          const registration = data.data;
          setRegistration(registration);

          const serialNumberIds = registration.serial_numbers.map((sn) => sn.id);

          const formatSerialNumbers = registration.serial_numbers.map((sn, index) => {
            if (sn.article_id) {
              return {
                id: index,
                serialnumber: sn.serialnumber,
                article_id: sn.article_id,
              };
            }

            return {
              id: index,
              serialnumber: sn.serialnumber,
            };
          });
          setSerialNumbers(formatSerialNumbers);

          setFormData({
            ...formData,
            promotion_id: registration.promotion.id,
            article_id: registration.article,
            purchase_date: registration.purchase_date,
            dealer_id: registration.dealer.id,
            serialnumberids: serialNumberIds,
            country_id: registration.address.country.id,
            zipcode: registration.address.zipcode,
            housenumber: registration.address.housenumber,
            streetname: registration.address.streetname,
            city: registration.address.city,
            promo_code: registration.promo_code ?? null,
            iban: registration.iban ?? null,
          });
        });
      }

      setFirstLoad(true);
    }
  }, [
    firstLoad,
    formData,
    userData.address.city,
    userData.address.country.id,
    userData.address.housenumber,
    userData.address.postal,
    userData.address.street,
  ]);

  const handleTerms = (e) => {
    e.target.checked
      ? setFormData({ ...formData, terms_and_conditions: 1 })
      : setFormData({ ...formData, terms_and_conditions: 0 });
  };

  const handleDealer = ({ value }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dealer_id: value,
      promotion_id: null,
      gift: null,
    }));
    resetDependentFields();
  };

  const handleProduct = ({ value }) => {
    const product = JSON.parse(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      article_id: product,
      purchase_date: null,
      promotion_id: null,
      gift: null,
    }));
    resetDependentFields();
  };

  const handleCountry = ({ value }) => {
    setFormData({ ...formData, country_id: value });
  };

  const resetDependentFields = () => {
    setPromotions([]);
    setGifts([]);
    setSelectedPromotion(null);
    setSelectedGift(null);
  };

  const handleSerialNumbers = (id, value, articleId = false) => {
    setSerialNumbers((prevSerialNumbers) => {
      const existingSerial = prevSerialNumbers.find((serial) => serial.id === id);

      if (existingSerial) {
        if (articleId) {
          return prevSerialNumbers.map((serial) =>
            serial.id === id ? { ...serial, serialnumber: value, article_id: articleId } : serial,
          );
        }

        return prevSerialNumbers.map((serial) => (serial.id === id ? { ...serial, serialnumber: value } : serial));
      }

      if (articleId) {
        return [...prevSerialNumbers, { id, serialnumber: value, article_id: articleId }];
      }

      return [...prevSerialNumbers, { id, serialnumber: value }];
    });
  };

  const handleSerialImageNames = (id, value) => {
    setSerieNummerName((prevSerieNummerName) => {
      const validSerieNummerName = prevSerieNummerName || [];
      const existing = validSerieNummerName.find((snn) => snn.id === id);

      if (existing) {
        return validSerieNummerName.map((snn) => (snn.id === id ? { ...snn, name: value } : snn));
      }

      return [...validSerieNummerName, { id, name: value }];
    });
  };

  const handleSerialImages = (id, value) => {
    setSerieNummer((prevSerieNummer) => {
      const validSerieNummer = prevSerieNummer || [];
      const existing = validSerieNummer.find((sn) => sn.id === id);

      if (existing) {
        return validSerieNummer.map((sn) => (sn.id === id ? { ...sn, image: value } : sn));
      }

      return [...validSerieNummer, { id, image: value }];
    });
  };

  useEffect(() => {
    if (products.length) {
      if (formData.article_id && formData.purchase_date && formData.dealer_id) {
        if (promotionsController !== formData.article_id.article_id + formData.purchase_date) {
          handlePromotions();
        }
      } else {
        setPromotions([]);
        setGifts([]);
        setSelectedPromotion(null);
        setSelectedGift(null);
        setFormData((prevFormData) => ({
          ...prevFormData,
          promotion_id: null,
          gift: null,
        }));
      }
    }
  }, [formData.article_id, formData.purchase_date, formData.dealer_id, products]);

  const handlePromotions = () => {
    if (formData.article_id === undefined || formData.purchase_date === null) {
      return;
    }
    setLoading(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}promotion/${formData.dealer_id}/${formData.article_id.article_id}/${formData.purchase_date}`,
        )
        .then((response) => {
          setPromotions(response.data.data);
          setPromotionsController(formData.article_id.article_id + formData.purchase_date);

          setLoading(false);
          if (response.data.data.length === 1) {
            handleGetGift(response.data.data[0]);
            return;
          }

          if (formData.promotion_id) {
            handleGetGift(response.data.data.find((p) => p.id === formData.promotion_id));
          }
        });
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
    return null;
  };

  const handleGetGift = (promotion) => {
    setSelectedGift(null);
    setGifts([]);
    setSelectedPromotion(promotion);
    setFormData((prevFormData) => ({
      ...prevFormData,
      gift: null,
      promotion_id: promotion.id,
    }));

    axios
      .get(`${process.env.REACT_APP_API_URL}gift/${promotion.id}/${formData.article_id.article_id}`)
      .then((response) => {
        if (!response.data.data.length) {
          toast(t("action-registration.no-gifts-found"), {
            position: "top-center",
            type: "error",
          });
          return;
        }

        setGifts(response.data.data);

        const gift = response.data.data[0];
        setSelectedGift({
          id: gift.id,
          name: gift.name,
        });

        if (registration.gift) {
          setSelectedGift(registration.gift);
          setFormData((prevFormData) => ({
            ...prevFormData,
            gift: gift.id,
          }));
          return;
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const handleGift = (gift) => {
    setSelectedGift({
      id: gift.value,
      name: gift.label,
    });

    setFormData({
      ...formData,
      gift: gift.value,
    });
  };

  const uploadImage = async (file, stateName, key = 0) => {
    if (file.size <= 5000000) {
      const fileType = file.type.split("/")[0];
      const fileName = file.name;

      if (stateName === "factuur") {
        setFactuurName(fileName);
      }

      if (stateName === "serieNummer") {
        handleSerialImageNames(key, file);
      }

      if (stateName === "actionCodeImage") {
        setActionCodeName(fileName);
      }

      if (fileType === "image") {
        file = await CompressImage(file);
      }

      const image = new FormData();
      image.append("file", file);
      axios
        .post(`${process.env.REACT_APP_API_URL}file/upload`, image, {
          headers: {
            ...axiosConfig.headers,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (stateName === "serieNummer") {
            handleSerialImages(key, response.data);
          } else if (stateName === "factuur") {
            setFactuur(response.data);
            setFormData({
              ...formData,
              invoice: response.data.url,
            });
          } else if (stateName === "actionCodeImage") {
            setActionCodeImage(response.data);
            setFormData({
              ...formData,
              actionCodeImage: response.data.url,
            });
          }
        })
        .catch(function (error) {
          setError(error.response.data.errors);
        });
    } else {
      if (stateName === "serieNummer") {
        setError({
          serialnumber_image: t("error.file-too-large"),
        });
      } else if (stateName === "factuur") {
        setError({
          purchase_image: t("error.file-too-large"),
        });
      }
    }
  };

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("action-registration.title")}>
        <div className="flex w-full items-center justify-between">
          <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
            {t("action-registration.edit-product")}
          </span>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-2 gap-x-4 gap-y-6">
          <div className="col-span-full">
            <p className="pb-3 text-sm">{t("general.product")}</p>
            {products.length > 0 && registration !== null && (
              <CustomSelect
                id="article_id"
                placeholder={t("platform-help.select-product")}
                options={products.map((product) => ({
                  value: JSON.stringify(product),
                  label: `${product.article_number} - ${product.name}`,
                }))}
                defaultValue={products
                  .filter((product) => product.article_id === formData.article_id?.article_id ?? null)
                  .map((product) => ({
                    value: JSON.stringify(product),
                    label: `${product.article_number} - ${product.name}`,
                  }))}
                onChange={handleProduct}
              />
            )}
            {formError.article_id && <span className="block mt-1 text-red-500">{formError.article_id}</span>}
          </div>
          <div className="col-span-full md:col-span-1">
            <p className="pb-3 text-sm">{t("general.date-of-purchase")}</p>
            <input
              id="purchase_date"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  purchase_date: e.target.value,
                  promotion_id: null,
                  gift: null,
                }));
                resetDependentFields();
              }}
              value={formData.purchase_date ?? null}
              className={`dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md ${formError.purchase_date && "outline outline-makita-red"}`}
            />
          </div>
          {formError.purchase_date && <span className="mt-2 text-red-500">{formError.purchase_date}</span>}

          <div className="col-span-full md:col-span-1">
            <p className="pb-3 text-sm">{t("general.dealer")}</p>
            {dealers.length > 0 && registration !== null && (
              <CustomSelect
                id="dealer_id"
                placeholder={t("general.select-dealer")}
                options={dealers.map((dealer) => ({
                  value: dealer.id,
                  label: dealer.name,
                }))}
                defaultValue={dealers
                  .filter((dealer) => dealer.id === formData.dealer_id ?? null)
                  .map((dealer) => ({
                    value: dealer.id,
                    label: dealer.name,
                  }))}
                onChange={handleDealer}
              />
            )}
          </div>
          {formError.dealer && <span className="block mt-1 text-red-500">{formError.dealer}</span>}

          {products.length > 0 && dealers.length > 0 && promotions.length > 0 && (
            <div className="col-span-full">
              <p className="pb-3 text-sm">{t("action-registration.registering-for")}:</p>
              <CustomSelect
                id="promotion_id"
                placeholder={t("general.select-promotion")}
                options={promotions.map((promotion) => ({
                  value: promotion.id,
                  label: promotion.name,
                }))}
                defaultValue={promotions
                  .filter((p) => p.id === selectedPromotion?.id)
                  .map((p) => ({
                    value: p.id,
                    label: p.name,
                  }))}
                onChange={({ value }) => handleGetGift(promotions.find((p) => p.id === parseInt(value)))}
                noOptionsMessage={t("action-registration.no-promotions-found")}
              />
              {formError.promotion_id && <span className="block mt-1 text-red-500">{formError.promotion_id}</span>}
            </div>
          )}

          {products.length && dealers.length && promotions.length && !(selectedGift instanceof Array) ? (
            <div className="col-span-full">
              <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <div className="col-span-full">
                  <CustomSelect
                    id="gift_id"
                    key={selectedPromotion?.id}
                    options={gifts.map((gift) => ({
                      value: gift.id,
                      label: gift.name,
                    }))}
                    value={
                      selectedGift
                        ? {
                            value: selectedGift.id,
                            label: selectedGift.name,
                          }
                        : null
                    }
                    onChange={(e) => handleGift(e)}
                  />
                  {formError.gift && <span className="block mt-1 text-red-500">{formError.gift}</span>}
                </div>

                {formData.article_id.children.length > 0 && registration !== null ? (
                  <>
                    <div className="col-span-full text-sm font-bold">{t("general.serialnumbers")}</div>
                    {formData.article_id.children.map((child, index) => (
                      <div key={index} className="col-span-full md:col-span-1">
                        <div className="mb-4">
                          <div>
                            <p className="pb-3 text-sm">
                              {t("general.serialnumber")} {child.article_number}
                            </p>
                            <InputMask
                              mask="999999999"
                              id="serialnumbers"
                              onChange={(e) => handleSerialNumbers(index, e.target.value, child.article_id)}
                              value={serialNumbers ? serialNumbers[index].serialnumber : ""}
                              placeholder={`${t("general.serialnumber")} ${child.article_number}`}
                              className={`dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md ${formError[`serialnumbers.${index}.serialnumber`] && "outline outline-makita-red"}`}
                              required
                            />
                          </div>
                          {formError[`serialnumbers.${index}.serialnumber`] &&
                            formError[`serialnumbers.${index}.serialnumber`].map((error, idx) => (
                              <span className="block mt-1 text-red-500" key={idx}>
                                {error}
                              </span>
                            ))}
                        </div>

                        <div className="mb-4">
                          <p className="pb-3 text-sm text-black/50 dark:text-white">
                            {t("general.serialnumber-example")} {child.article_number}
                          </p>
                          <div className="card dark:glass relative mx-auto w-full rounded-xl bg-white p-4 shadow-md">
                            <p className="pb-3 text-sm text-black/50 dark:text-white">
                              {t("general.serialnumber-placeholder")}
                            </p>
                            <div className="flex w-full justify-center">
                              <img
                                src={child.serialnumber_photo}
                                alt="productnummer voorbeeld"
                                className="max-h-[200px] rounded-xl"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-2">
                          <div>
                            <label>
                              <input
                                type="file"
                                hidden
                                id="serialnumber_photo"
                                onChange={(e) => uploadImage(e.target.files[0], "serieNummer", index)}
                              />
                              <p className="pb-3 text-sm">
                                {t("general.serialnumber-photo")} {child.article_number}
                              </p>
                              <div className={`btn btn--secondary justify-start ${formError[`serialnumber_image.${index}`] && "outline outline-makita-red"}`}>
                                <FontAwesomeIcon icon="fa-regular fa-paperclip" />{" "}
                                {t("general.serialnumber-photo-upload")}
                              </div>
                            </label>
                          </div>
                          {formError[`serialnumber_image.${index}`] && (
                            <span className="block mt-1 text-red-500">{t("error.serial-image-required")}</span>
                          )}

                          {serieNummer.length > 0 &&
                            serieNummer.find((sn) => sn.id === index) &&
                            (serieNummer
                              .find((sn) => sn.id === index)
                              .image.url.split(".")
                              .pop() === "pdf" ? (
                              <div className="mt-3">
                                <FontAwesomeIcon icon="fa-regular fa-file-pdf" size="xl" /> {serieNummerName}
                              </div>
                            ) : (
                              <img
                                src={
                                  serieNummer.find((sn) => sn.id === index).image.url +
                                  "?token=" +
                                  localStorage.getItem("userAuth")
                                }
                                className="mt-3 w-1/2 object-cover"
                                alt="serienummer foto"
                              />
                            ))}
                        </div>

                        <a
                          className="dark:glass mt-4 flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                          href={`${registration.serial_numbers[index].serialnumber_image}?token=${localStorage.getItem("userAuth")}`}
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                          {t("general.serialnumber")} {child.article_number}
                        </a>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="col-span-full md:col-span-1">
                      <div>
                        <p className="pb-3 text-sm">{t("general.serialnumber")}</p>
                        <InputMask
                          mask="999999999"
                          id="serialnumbers"
                          onChange={(e) => handleSerialNumbers(0, e.target.value)}
                          value={serialNumbers ? serialNumbers[0]?.serialnumber : ""}
                          placeholder={t("general.serialnumber")}
                          className={`dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md ${formError["serialnumbers.0.serialnumber"] && "outline outline-makita-red"}`}
                          required
                        />
                      </div>
                      {formError["serialnumbers.0.serialnumber"] &&
                        formError["serialnumbers.0.serialnumber"].map((item, index) => (
                          <span className="block mt-1 text-red-500" key={index}>
                            {item}
                          </span>
                        ))}
                    </div>

                    {formData.article_id && (
                      <div className="col-span-full md:col-span-1">
                        <p className="pb-3 text-sm text-black/50 dark:text-white">
                          {t("general.serialnumber-example")}
                        </p>
                        <div className="card dark:glass relative mx-auto w-full rounded-xl bg-white p-4 shadow-md">
                          <p className="pb-3 text-sm text-black/50 dark:text-white">
                            {t("general.serialnumber-placeholder")}
                          </p>
                          <div className="flex w-full justify-center">
                            <img
                              src={formData.article_id.serialnumber_photo}
                              alt="productnummer voorbeeld"
                              className="max-h-[200px] rounded-xl"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-span-full md:col-span-1">
                      <div>
                        <label>
                          <input
                            type="file"
                            hidden
                            id="serialnumber_photo"
                            onChange={(e) => uploadImage(e.target.files[0], "serieNummer")}
                          />
                          <p className="pb-3 text-sm">{t("general.serialnumber-photo")}</p>
                          <div
                            className={`btn btn--secondary justify-start ${formError["serialnumber_image.0"] && "outline outline-makita-red"}`}>
                            <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.serialnumber-photo-upload")}
                          </div>
                        </label>
                      </div>
                      {formError["serialnumber_image.0"] && (
                        <span className="block mt-1 text-red-500">{t("error.serial-image-required")}</span>
                      )}

                      {serieNummer.length > 0 &&
                        serieNummer[0] &&
                        (serieNummer[0].image.url.split(".").pop() === "pdf" ? (
                          <div className="mt-3">
                            <FontAwesomeIcon icon="fa-regular fa-file-pdf" size="xl" /> {serieNummerName}
                          </div>
                        ) : (
                          <img
                            src={serieNummer[0].image.url + "?token=" + localStorage.getItem("userAuth")}
                            className="mt-3 w-1/2 object-cover"
                            alt="serienummer foto"
                          />
                        ))}

                      <a
                        className="dark:glass mt-4 flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                        href={`${registration.serial_numbers[0].serialnumber_image}?token=${localStorage.getItem("userAuth")}`}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                        {t("general.serialnumber")}
                      </a>
                    </div>
                  </>
                )}

                <div className="col-span-full md:col-span-1">
                  <div>
                    <label>
                      <input
                        type="file"
                        hidden
                        id="purchase_image"
                        onChange={(e) => uploadImage(e.target.files[0], "factuur")}
                      />
                      <p className="pb-3 text-sm">{t("general.invoice-photo")}</p>
                      <div
                        className={`btn btn--secondary justify-start ${formError.purchase_image && "outline outline-makita-red"}`}>
                        <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.invoice-upload")}
                      </div>
                    </label>
                  </div>
                  {formError.purchase_image && (
                    <span className="block mt-1 text-red-500">{t("error.invoice-image-required")}</span>
                  )}

                  {factuur.url &&
                    (factuur.url.split(".").pop() === "pdf" ? (
                      <div className="mt-3">
                        <FontAwesomeIcon icon="fa-regular fa-file-pdf" size="xl" /> {factuurName}
                      </div>
                    ) : (
                      <img
                        src={factuur.url + "?token=" + localStorage.getItem("userAuth")}
                        className="mt-3 w-1/2 object-cover"
                        alt="factuur foto"
                      />
                    ))}

                  <a
                    className="dark:glass mt-4 flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                    href={`${registration.purchase_image}?token=${localStorage.getItem("userAuth")}`}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                    {t("general.invoice")}
                  </a>
                </div>

                <div className="col-span-full">
                  <hr className="border-line" />
                </div>

                <div className="col-span-full">
                  <p className="pb-3 text-sm">{t("general.country")}</p>
                  <CustomSelect
                    id="country_id"
                    placeholder={t("general.select-country")}
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    defaultValue={countries
                      .filter((country) => country.id === formData.country_id)
                      .map((country) => ({
                        value: country.id,
                        label: country.name,
                      }))}
                    onChange={handleCountry}
                  />
                </div>

                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.zip-code")}</p>
                  <input
                    id="zipcode"
                    value={formData.zipcode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        zipcode: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.housenumber")}</p>
                  <input
                    id="housenumber"
                    value={formData.housenumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        housenumber: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.streetname")}</p>
                  <input
                    id="streetname"
                    value={formData.streetname}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        streetname: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.residence")}</p>
                  <input
                    id="city"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        city: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>

                {selectedPromotion && selectedPromotion.type === 1 && (
                  <>
                    <div className="col-span-full">
                      <hr className="border-line" />
                    </div>

                    <div className="col-span-full md:col-span-1">
                      <p className="pb-3 text-sm">{t("general.promo-code")}</p>
                      <input
                        id="promo_code"
                        value={formData.promo_code}
                        placeholder={t("general.promo-code")}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            promo_code: e.target.value,
                          })
                        }
                        className={`dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md ${formError.promo_code && "outline outline-makita-red"}`}
                      />
                      {formError.promo_code && <span className="block mt-1 text-red-500">{formError.promo_code}</span>}
                    </div>

                    <div className="col-span-full md:col-span-1">
                      <div>
                        <label>
                          <input
                            type="file"
                            hidden
                            id="action_code_image"
                            onChange={(e) => uploadImage(e.target.files[0], "actionCodeImage")}
                          />
                          <p className="pb-3 text-sm">{t("general.promo-code-image")}</p>
                          <div
                            className={`btn btn--secondary justify-start ${formError.action_code_image && "outline outline-makita-red"}`}>
                            <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.promo-code-upload")}
                          </div>
                        </label>
                      </div>
                      {formError.action_code_image && (
                        <span className="block mt-1 text-red-500">{t("error.action-code-image-required")}</span>
                      )}

                      {actionCodeImage.url &&
                        (actionCodeImage.url.split(".").pop() === "pdf" ? (
                          <div className="mt-3">
                            <FontAwesomeIcon icon="fa-regular fa-file-pdf" size="xl" /> {actionCodeName}
                          </div>
                        ) : (
                          <img
                            src={actionCodeImage.url + "?token=" + localStorage.getItem("userAuth")}
                            className="mt-3 w-1/2 object-cover"
                            alt="actie code foto"
                          />
                        ))}

                      <a
                        className="dark:glass mt-4 flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                        href={`${registration.action_code_image}?token=${localStorage.getItem("userAuth")}`}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                        {t("general.promo-code-image")}
                      </a>
                    </div>

                    <div className="col-span-full">
                      <p className="pb-3 text-sm">{t("general.iban")}</p>
                      <input
                        id="promo_code"
                        value={formData.iban}
                        placeholder={t("general.iban")}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            iban: e.target.value,
                          })
                        }
                        className={`dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md ${formError.iban && "outline outline-makita-red"}`}
                      />
                      {formError.iban && <span className="block mt-1 text-red-500">{formError.iban}</span>}
                    </div>
                  </>
                )}

                <div className="col-span-full">
                  <input type="checkbox" id="privacy_statement" required onChange={(e) => handleTerms(e)} />
                  <span className="ml-2 text-sm">
                    <Trans
                      i18nKey="general.privacy-policy-consent"
                      components={{
                        link1: (
                          <a
                            href={process.env.REACT_APP_PRIVACY_POLICY}
                            className="link text-primary dark:text-primary-light"
                          />
                        ),
                      }}
                    />
                  </span>
                </div>

                <div className="col-span-full">
                  <button type="submit" className="btn btn--primary w-full" value="registreren">
                    {t("general.update-registration")}
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p className="dark:glass/20 col-span-full border border-line/20 bg-white/50 px-4 py-3">
              {formData.article_id && formData.purchase_date && formData.dealer_id
                ? promotions.length && !selectedGift.length
                  ? t("error.no-available-gift")
                  : t("error.no-running-action")
                : t("action-registration.fill-data")}
            </p>
          )}
        </form>
      </Layout>
    </>
  );
};

export default ProductRegistrationEdit;
